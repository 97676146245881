<!-- For commentary and documentation, see EcartTemperature.vue (structure is almost the same) -->

<template>
  <v-container :class="isMobile ? 'pa-0' : ''">
    <p class="mean-temperature-title">
      {{ $t("city." + city.name + ".name") }}
      {{ $t("city-page.city-weather.graph1.title") }}
      {{ currentDate }}
    </p>
    <p class="mean-temperature-subheader">
      {{ $t("city-page.city-weather.graph1.subtitle") }}
    </p>
    <canvas ref="monthlyChart" class="city-weather-chart"></canvas>
    <v-row class="mt-3 mb-3">
      <v-col>
        <button
          id="mean-temperature-legend-btn-81"
          class="mean-temperature-legend-btn"
          @click="toggleEntry('mean-temperature-legend-btn-81')"
        >
          <v-icon class="mr-1 pb-1" :color="legendColor[0]">mdi-circle</v-icon>
          <span>1981-2010</span>
        </button>
        <button
          id="mean-temperature-legend-btn-11"
          class="
            mean-temperature-legend-btn mean-temperature-legend-btn-inactive
          "
          @click="toggleEntry('mean-temperature-legend-btn-11')"
        >
          <v-icon class="mr-1 pb-1" :color="legendColor[1]">mdi-circle</v-icon>
          <span>2011-2020</span>
        </button>
        <button
          id="mean-temperature-legend-btn-21"
          class="mean-temperature-legend-btn"
          @click="toggleEntry('mean-temperature-legend-btn-21')"
        >
          <v-icon class="mr-1 pb-1" :color="legendColor[2]">mdi-circle</v-icon>
          <span>{{ currentDate }}</span>
        </button>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Chart from "chart.js/auto";
import axios from "axios";

export default {
  name: "TemperatureMoyenne",
  props: {
    city: {
      required: true,
      type: Object,
    },
    isMobile: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentDate: new Date().getFullYear(),
      legendColor: ["#fcc850", "#ea9c4a", "#8b2845"],
      monthlyChart: null,
    };
  },
  async mounted() {
    await this.loadMonthlyData();
    this.addMonthlyChart();
  },
  methods: {
    async loadMonthlyData() {
      let nPMonthlyResponse;
      try {
        // Get data from local Nasa Power api for historical weather information for charts
        nPMonthlyResponse = await axios.get(
          process.env.VUE_APP_NASA_POWER_LOCAL_URL + this.city.name + "/monthly"
        );
        nPMonthlyResponse = nPMonthlyResponse.data;
        if (!this.city.weather) {
          this.city.weather = {};
        }
        this.city.weather.monthly = nPMonthlyResponse;
      } catch (error) {
        console.error(error);
      }
    },
    addMonthlyChart() {
      if (this.monthlyChart) {
        this.monthlyChart.destroy();
      }

      Chart.defaults.font.family = "BR Candor";

      this.monthlyChart = new Chart(this.$refs.monthlyChart, {
        type: "line",
        data: {
          labels: this.$t("city-page.weather.months"),
          datasets: [
            {
              label: "Température moyenne " + this.currentDate,
              borderColor: "#8b2845",
              backgroundColor: "#8b2845",
              data: this.city.weather.monthly.T_2021,
              borderDash: [5, 5],
              hidden: false,
            },
            {
              label: "Température moyenne 2011-2020 ",
              borderColor: "#ea9c4a",
              backgroundColor: "#ea9c4a",
              data: this.city.weather.monthly.T_10_y,
              hidden: true,
            },
            {
              label: "Température moyenne 1981-2010 ",
              borderColor: "#fcc850",
              backgroundColor: "#fcc850",
              data: this.city.weather.monthly.T_30,
              hidden: false,
            },
          ],
        },

        options: {
          elements: {
            line: {
              borderWidth: 2.8,
              tension: 0.25,
              cubicInterpolationMonde: "monotone",
              borderCapStyle: "round",
              borderJoinStyle: "round",
            },
            point: {
              pointRadius: 0,
              hitRadius: 40,
              hoverRadius: 6,
              hoverBorderWidth: 2,
            },
          },
          plugins: {
            legend: {
              reverse: true,
              display: false,
              position: "bottom",
              labels: {
                font: {
                  size: 14,
                  family: "BR Candor",
                },
              },
            },
            tooltip: {
              enabled: true,
              mode: "index",
              position: "nearest",
              backgroundColor: "rgb(255,255,255,0.9)",
              titleColor: "rgb(0,0,0)",
              bodyColor: "rgb(0,0,0)",
              bodySpacing: 4,
              caretPadding: 5,
              borderWidth: 0.7,
              borderColor: "rgba(217, 217, 217)",
              callbacks: {
                label: (context) => {
                  var multistring = [];
                  if (this.$i18n.locale === "fr") {
                    multistring.push(
                      context.dataset.label + " : " + context.formattedValue
                    );
                  } else {
                    var translatedString;
                    if (
                      context.dataset.label ===
                      "Température moyenne " + this.currentDate
                    ) {
                      translatedString =
                        "Average temperature " + this.currentDate;
                    } else if (
                      context.dataset.label === "Température moyenne 1981-2010 "
                    ) {
                      translatedString = "Average temperature 1981-2010";
                    } else {
                      translatedString = "Average temperature 2011-2020";
                    }
                    multistring.push(
                      translatedString + " : " + context.formattedValue
                    );
                  }
                  return multistring;
                },
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: {
                display: false,
              },
              gridLines: {
                display: false,
              },
            },
            y: {
              type: "linear",
              position: "left",
              grid: {
                display: true,
                tickLength: 0,
                color: function (context) {
                  if (context.tick.value === 0) {
                    return "#000000";
                  } else {
                    return "#e6e6e6";
                  }
                },
              },
              ticks: {
                padding: 7,
              },
            },
          },
        },
      });
    },
    toggleEntry(legend) {
      var element = document.getElementById(legend);
      var scale = legend.slice(-2);

      // Update Button style
      if (element.classList.contains("mean-temperature-legend-btn-inactive")) {
        element.classList.remove("mean-temperature-legend-btn-inactive");
        // Display or Hide data
        if (scale === "81") {
          this.monthlyChart.setDatasetVisibility(2, true);
        } else if (scale === "11") {
          this.monthlyChart.setDatasetVisibility(1, true);
        } else {
          this.monthlyChart.setDatasetVisibility(0, true);
        }
      } else {
        element.classList.add("mean-temperature-legend-btn-inactive");
        // Display or Hide data
        if (scale === "81") {
          this.monthlyChart.setDatasetVisibility(2, false);
        } else if (scale === "11") {
          this.monthlyChart.setDatasetVisibility(1, false);
        } else {
          this.monthlyChart.setDatasetVisibility(0, false);
        }
      }
      this.monthlyChart.update();
    },
  },
};
</script>

<style scoped>
.mean-temperature-title {
  font-size: 1.2vw;
  color: #33549b;
  font-weight: bold;
}

.mean-temperature-subheader {
  color: #aabcc6;
  font-size: 0.8vw;
}

.mean-temperature-legend-btn {
  margin-right: 5%;
  font-size: 0.9vw;
}

.mean-temperature-legend-btn-inactive {
  opacity: 0.25;
}

.city-weather-chart {
  background-color: white;
  border-radius: 15px;
  padding: 1% 3% 1% 1%;
}
@media screen and (min-width: 800px) {
  .mean-temperature-legend-btn:hover {
    opacity: 0.25;
  }

  .mean-temperature-legend-btn-inactive:hover {
    opacity: 0.75;
  }
}

@media screen and (max-width: 800px) {
  .mean-temperature-title {
    font-size: 24px;
  }

  .mean-temperature-subheader {
    font-size: 16px;
  }

  .mean-temperature-legend-btn {
    font-size: 14px;
  }

  .city-weather-chart {
    max-height: 300px;
  }
}
</style>
