<template>
  <!-- right column graph -->
  <v-container>
    <p class="ecart-temperature-title">
      {{ $t("city." + city.name + ".name") }}
      {{ $t("city-page.city-weather.graph2.title") }}
    </p>
    <p class="ecart-temperature-subheader">
      {{ $t("city-page.city-weather.graph2.subtitle") }}
    </p>
    <canvas ref="ecartChart" class="city-weather-chart"></canvas>
    <!-- Legend buttons -->
    <v-row class="mt-3 mb-3">
      <v-col>
        <button
          id="ecart-temperature-legend-btn-city"
          class="ecart-temperature-legend-btn"
          @click="toggleEntry('ecart-temperature-legend-btn-city')"
        >
          <v-icon class="mr-1 pb-1" :color="legendColor[0]">mdi-circle</v-icon>
          <span>{{ $t("city." + city.name + ".name") }}</span>
        </button>
        <button
          id="ecart-temperature-legend-btn-global"
          class="
            ecart-temperature-legend-btn ecart-temperature-legend-btn-inactive
          "
          @click="toggleEntry('ecart-temperature-legend-btn-global')"
        >
          <v-icon class="mr-1 pb-1" :color="legendColor[1]">mdi-circle</v-icon>
          <span>{{
            $t("city-page.city-weather.graph2.all-cities-legend")
          }}</span>
        </button>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Chart from "chart.js/auto";
import axios from "axios";

export default {
  name: "EcartTemperature",
  props: {
    city: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      chartLabel: [],
      dataMoy: [],
      legendColor: ["#61c0ff", "#12368c"],
      ecartChart: null,
      allCitiesStat: undefined,
    };
  },
  async mounted() {
    await this.loadEcart();
    await this.loadMoy();
    this.chartLabel = [];
    for (var key in this.city.weather.ecartYearly.YEAR) {
      this.chartLabel.push(this.city.weather.ecartYearly.YEAR[key]);
    }
    this.addEcartChart();
  },
  methods: {
    // Load mean deviation data
    async loadEcart() {
      let ecartResponse;
      try {
        ecartResponse = await axios.get(
          process.env.VUE_APP_NASA_POWER_LOCAL_URL + this.city.name + "/ecart"
        );
        ecartResponse = ecartResponse.data;
        if (!this.city.weather) {
          this.city.weather = {};
        }
        this.city.weather.ecartYearly = ecartResponse;
      } catch (error) {
        console.error(error);
      }
    },
    // Load average mean deviation data
    async loadMoy() {
      try {
        var response = await axios.get(
          process.env.VUE_APP_NASA_POWER_LOCAL_URL + "/ecart"
        );
        this.allCitiesStat = Object.entries(response.data);
        this.dataMoy = [];
        this.allCitiesStat.forEach((year) => {
          this.dataMoy.push(year[1].Moyenne);
        });
      } catch (error) {
        console.error(error);
      }
    },
    // Init chart.js graph
    addEcartChart() {
      var self = this;
      if (this.ecartChart) {
        this.ecartChart.destroy();
      }
      var name = this.city.name;
      var data = [];
      for (var key in this.city.weather.ecartYearly.ecart_city) {
        data.push(this.city.weather.ecartYearly.ecart_city[key]);
      }

      Chart.defaults.font.family = "BR Candor";
      this.ecartChart = new Chart(this.$refs.ecartChart, {
        type: "line",
        data: {
          labels: self.chartLabel,
          datasets: [
            {
              label: name,
              borderColor: "#61c0ff",
              backgroundColor: "#61c0ff",
              data: data,
            },
            {
              label: "Toutes les villes Klover",
              borderColor: "#12368c",
              backgroundColor: "#12368c",
              data: self.dataMoy,
              hidden: true,
            },
          ],
        },
        options: {
          elements: {
            line: {
              borderWidth: 2.8,
              tension: 0.25,
              cubicInterpolationMonde: "monotone",
              borderCapStyle: "round",
              borderJoinStyle: "round",
            },
            point: {
              pointRadius: 0,
              hitRadius: 40,
              hoverRadius: 6,
              hoverBorderWidth: 2,
            },
          },
          plugins: {
            legend: {
              reverse: true,
              display: false,
              position: "bottom",
              labels: {
                font: {
                  size: 14,
                  family: "BR Candor",
                },
              },
            },
            tooltip: {
              enabled: true,
              mode: "index",
              position: "nearest",
              backgroundColor: "rgb(255,255,255,0.9)",
              titleColor: "rgb(0,0,0)",
              bodyColor: "rgb(0,0,0)",
              bodySpacing: 4,
              caretPadding: 5,
              borderWidth: 0.7,
              borderColor: "rgba(217, 217, 217)",
              callbacks: {
                label: (context) => {
                  var multistring = [];
                  if (context.dataset.label === this.city.name) {
                    multistring.push(
                      this.$t("city." + this.city.name + ".name") +
                        " : " +
                        context.formattedValue
                    );
                  }
                  if (context.dataset.label === "Toutes les villes Klover") {
                    multistring.push(
                      this.$t(
                        "city-page.city-weather.graph2.all-cities-legend"
                      ) +
                        " : " +
                        context.formattedValue
                    );
                  }
                  return multistring;
                },
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              type: "linear",
              position: "left",
              grid: {
                display: true,
                tickLength: 0,
                color: function (context) {
                  if (context.tick.value === 0) {
                    return "#000000";
                  } else {
                    return "#e6e6e6";
                  }
                },
              },
              ticks: {
                padding: 7,
              },
            },
          },
        },
      });
    },
    // Toggle legend color and chart dataset
    toggleEntry(legend) {
      var element = document.getElementById(legend);
      var scale = legend.slice(-4);

      // Update Button style
      if (element.classList.contains("ecart-temperature-legend-btn-inactive")) {
        element.classList.remove("ecart-temperature-legend-btn-inactive");
        // Display or Hide data
        if (scale === "city") {
          this.ecartChart.setDatasetVisibility(0, true);
        } else {
          this.ecartChart.setDatasetVisibility(1, true);
        }
      } else {
        element.classList.add("ecart-temperature-legend-btn-inactive");
        // Display or Hide data
        if (scale === "city") {
          this.ecartChart.setDatasetVisibility(0, false);
        } else {
          this.ecartChart.setDatasetVisibility(1, false);
        }
      }
      this.ecartChart.update();
    },
  },
};
</script>

<style scoped>
.ecart-temperature-title {
  font-size: 1.2vw;
  color: #33549b;
  font-weight: bold;
}

.ecart-temperature-subheader {
  color: #aabcc6;
  font-size: 0.8vw;
}

.ecart-temperature-legend-btn {
  margin-right: 5%;
  font-size: 0.9vw;
}

.ecart-temperature-legend-btn-inactive {
  opacity: 0.25;
}

.city-weather-chart {
  background-color: white;
  border-radius: 15px;
  padding: 1% 3% 1% 1%;
}

@media screen and (min-width: 800px) {
  .ecart-temperature-legend-btn:hover {
    opacity: 0.25;
  }

  .ecart-temperature-legend-btn-inactive:hover {
    opacity: 0.75;
  }
}

@media screen and (max-width: 800px) {
  .ecart-temperature-title {
    font-size: 24px;
  }

  .ecart-temperature-subheader {
    font-size: 16px;
  }

  .ecart-temperature-legend-btn {
    font-size: 14px;
  }

  .city-weather-chart {
    max-height: 300px;
  }
}
</style>
