<template>
  <!-- Background image -->
  <v-img
    v-if="!isMobile"
    :src="require('@/assets/images/city-text.jpg')"
    class="city-text-img"
    gradient="180deg, rgba(249,223,151,0) 0%, rgba(152,196,165,0.4) 50%, rgba(99,202,128,0.75) 100%"
  >
    <v-container fill-height>
      <v-card color="#f4f3f1" :class="isMobile ? 'card' : 'card ma-auto mr-15'">
        <h2 :class="isMobile ? 'city-text-title' : 'city-text-title pb-5'">
          {{ $t("city." + city.name + ".name") }}
          {{ $t("city-page.city-text.title") }}
        </h2>
        <p v-if="!isMobile" class="city-text-content">
          {{ $t("city." + city.name + ".action1")
          }}<a
            target="_blank"
            :href="this.$t('city.' + city.name + '.action1-link-href')"
            >{{ $t("city." + city.name + ".action1-link") }}</a
          >{{ $t("city." + city.name + ".action2") }}
        </p>
        <p v-if="city['has-2-link']" class="city-text-content mt-5">
          {{ $t("city." + city.name + ".link2")
          }}<a
            target="_blank"
            :href="this.$t('city.' + city.name + '.action2-href')"
            >{{ $t("city." + city.name + ".action2-link") }}</a
          >
        </p>
      </v-card>
    </v-container>
  </v-img>
  <div v-else>
    <v-container fill-height style="background-color: #fdd687">
      <v-card color="#f4f3f1" :class="isMobile ? 'card' : 'card ma-auto mr-15'">
        <h2 :class="isMobile ? 'city-text-title' : 'city-text-title pb-5'">
          {{ $t("city." + city.name + ".name") }}
          {{ $t("city-page.city-text.title") }}
        </h2>
        <p class="city-text-content">
          {{ $t("city." + city.name + ".action1")
          }}<a
            target="_blank"
            :href="this.$t('city.' + city.name + '.action1-link-href')"
            >{{ $t("city." + city.name + ".action1-link") }}</a
          >{{ $t("city." + city.name + ".action2") }}
        </p>
        <p v-if="city['has-2-link']" class="city-text-content mt-5">
          {{ $t("city." + city.name + ".link2")
          }}<a
            target="_blank"
            :href="this.$t('city.' + city.name + '.action2-href')"
            >{{ $t("city." + city.name + ".action2-link") }}</a
          >
        </p>
      </v-card>
    </v-container>
    <v-img
      :src="require('@/assets/images/city-text.jpg')"
      class="city-text-img"
      gradient="180deg, rgba(249,223,151,0) 0%, rgba(152,196,165,0.4) 50%, rgba(99,202,128,0.75) 100%"
    />
  </div>
</template>

<script>
export default {
  name: "CityText",
  props: {
    city: {
      type: Object,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped>
.card {
  padding: 2%;
  border-radius: 15px;
}

.city-text-img {
  height: 34vw;
}

.city-text-title {
  font-size: 1.5vw;
  color: #113689;
}

.city-text-content {
  font-size: 0.9vw;
  white-space: pre-line;
  max-width: 50vw;
  color: #113689;
}

@media screen and (max-width: 800px) {
  .card {
    padding: 7%;
    margin-top: 4%;
  }

  .city-text-title {
    font-size: 24px;
    margin-bottom: 6%;
  }

  .city-text-content {
    font-size: 14px;
    max-width: 95vw;
  }

  .city-text-img {
    height: 100vw;
  }

  @media screen and (orientation: landscape) {
    .city-text-title {
      font-size: 24px;
      max-width: 95vw;
    }

    .city-text-content {
      font-size: 16px;
      max-width: 95vw;
      white-space: inherit;
    }
  }
}
</style>
