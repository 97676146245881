<template>
  <!-- Focus Klover section -->
  <div id="city-square" class="city-square">
    <!-- Left section -->
    <div class="city-square-left">
      <div class="city-square-left-title-div">
        <v-img
          :src="require('@/assets/images/klover-focus.png')"
          :lazy-src="require('@/assets/images/klover-focus.png')"
          class="klover-focus-icon-class"
          contain
        />
        <div class="city-square-left-title-texts">
          <p class="city-square-left-title-title">
            {{ $t("city-page.city-square.title") }}
          </p>
          <p class="city-square-left-title-subtitle">
            {{ $t("city-page.city-square.subtitle")
            }}{{ $t("city." + city.name + ".name")
            }}<template v-if="$i18n.locale === 'en'">{{
              $t("city-page.city-square.subtitle2")
            }}</template>
          </p>
        </div>
      </div>

      <div class="city-square-left-greeness">
        <!-- Greeness bubble (on progressBar) -->
        <div
          ref="citySquareLeftGreenessBubble"
          class="city-square-left-greeness-bubble"
        >
          <div class="city-square-left-greeness-bubble-percentage">
            {{ Math.round(greeness * 100)
            }}<span class="span-percentage">%</span>
          </div>
        </div>
        <!-- Progressive bar -->
        <v-progress-linear
          :value="greeness * 100"
          height="15"
          rounded
          color="#8ebf83"
          style="border-radius: 25px"
        />
        <div class="city-square-left-greeness-numbers">
          <!-- Green area -->
          <div class="city-square-left-greeness-numbers-item">
            <div class="city-square-left-greeness-numbers-title">
              {{ Math.round(city.SurfVeg_m2) / 100 }} km&#xB2;
            </div>
            <p class="city-square-left-greeness-numbers-subtitle">
              {{ $t("city-page.city-square.bar.left") }}
            </p>
          </div>

          <!-- Urban area -->
          <div class="city-square-left-greeness-numbers-item">
            <div
              class="
                city-square-left-greeness-numbers-title
                city-square-right-greeness-numbers-title
              "
            >
              {{ Math.round(city.SurfNoVeg_m2) / 100 }} km&#xB2;
            </div>
            <p class="city-square-left-greeness-numbers-subtitle">
              {{ $t("city-page.city-square.bar.right") }}
            </p>
          </div>
        </div>
      </div>

      <!-- Numbers sections -->
      <div class="city-square-left-grid">
        <div class="city-square-left-grid-right-item">
          <v-icon
            v-if="!isMobile"
            class="city-square-left-grid-right-icon"
            size="3.5vw"
            >$vuetify.icon.canopy_active</v-icon
          >
          <v-icon
            v-if="isMobile"
            class="city-square-left-grid-right-icon"
            size="35px"
            >$vuetify.icon.canopy_active</v-icon
          >
          <div class="city-square-left-grid-right-item-text">
            <p class="city-square-left-grid-right-item-title">
              {{ Math.round(city.SurfTAHab) }} m&#xB2;
            </p>
            <p class="city-square-left-grid-right-item-subtitle">
              {{ $t("city-page.city-square.statistics.stat1") }}
            </p>
          </div>
        </div>

        <div class="city-square-left-grid-right-item">
          <v-icon
            v-if="!isMobile"
            class="city-square-left-grid-right-icon"
            size="3.5vw"
          >
            $vuetify.icon.walk_active
          </v-icon>
          <v-icon
            v-if="isMobile"
            class="city-square-left-grid-right-icon"
            size="35px"
          >
            $vuetify.icon.walk_active
          </v-icon>
          <div class="city-square-left-grid-right-item-text">
            <p class="city-square-left-grid-right-item-title">
              {{ city.AccesParcMoy }} min
            </p>
            <p class="city-square-left-grid-right-item-subtitle">
              {{ $t("city-page.city-square.statistics.stat2") }}
            </p>
          </div>
        </div>

        <div class="city-square-left-grid-right-item">
          <v-icon
            v-if="!isMobile"
            class="city-square-left-grid-right-icon"
            size="3.5vw"
          >
            $vuetify.icon.connectivity_active
          </v-icon>
          <v-icon
            v-if="isMobile"
            class="city-square-left-grid-right-icon"
            size="35px"
          >
            $vuetify.icon.connectivity_active
          </v-icon>
          <div class="city-square-left-grid-right-item-text">
            <p class="city-square-left-grid-right-item-title">
              {{ Math.round(city.VegConnect) }} /100
            </p>
            <p class="city-square-left-grid-right-item-subtitle">
              {{ $t("city-page.city-square.statistics.stat3") }}
            </p>
          </div>
        </div>

        <div class="city-square-left-grid-right-item">
          <v-icon
            v-if="!isMobile"
            class="city-square-left-grid-right-icon"
            size="3.5vw"
          >
            $vuetify.icon.tree_active
          </v-icon>
          <v-icon
            v-if="isMobile"
            class="city-square-left-grid-right-icon"
            size="35px"
          >
            $vuetify.icon.tree_active
          </v-icon>
          <div class="city-square-left-grid-right-item-text">
            <p class="city-square-left-grid-right-item-title">
              {{ Math.round(city.perc_ta) }} %
              <info-btn
                v-if="!isMobile"
                :canopy="true"
                style="position: absolute; top: 0; left: 75%"
              />
            </p>
            <p class="city-square-left-grid-right-item-subtitle">
              {{ $t("city-page.city-square.statistics.stat4") }}
            </p>
          </div>
        </div>

        <!-- <a class="city-square-left-greeness-button" href="/about">
          En savoir plus sur les <br />indicateurs
        </a> -->

        <!-- Download button -->
        <button
          v-if="!isMobile"
          class="city-square-left-greeness-button"
          @click="toggleDisplayPopup"
        >
          {{ $t("city-page.city-square.download-btn") }}
          <v-icon color="#64c0ff">mdi-download</v-icon>
        </button>
      </div>
    </div>
    <div class="map-container-div">
      <!-- map on the right -->
      <CityMap :is-mobile="isMobile" :city="city"></CityMap>
      <p style="float: right">
        <a
          style="color: white; text-decoration: inherit; font-size: 14px"
          href="http://www.openmaptiles.org/"
          target="__blank"
          >© OpenMapTiles
        </a>
        <a
          style="color: white; text-decoration: inherit; font-size: 14px"
          href="https://www.openstreetmap.org/copyright"
          target="__blank"
          >© OpenStreetMap contributors</a
        >
      </p>
    </div>
    <!-- Popup to download data -->
    <Popup
      :display="displayPopup"
      :fr-link="city.frLink"
      :en-link="city.enLink"
      @closePopup="closePopUp"
    />
  </div>
</template>

<script>
import CityMap from "@/components/City/CityMap";
import InfoBtn from "@/components/Description/information/InfoBtn";
import Popup from "@/components/City/download/Popup";

export default {
  name: "CitySquare",
  components: {
    CityMap,
    InfoBtn,
    Popup,
  },
  props: {
    city: {
      type: Object,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    greeness: 0.1,
    displayPopup: false,
  }),
  mounted() {
    // Compute greeness value
    this.greeness =
      this.city.SurfVeg_m2 / (this.city.SurfVeg_m2 + this.city.SurfNoVeg_m2);
    // Adapt bubble position according to greeness value
    this.manageBubblePosition();
  },
  updated() {
    this.manageBubblePosition();
  },

  methods: {
    // Function to close popup
    closePopUp() {
      this.displayPopup = false;
    },
    // Adapt bubble position depending on greenness value
    manageBubblePosition() {
      if (!this.isMobile) {
        if (this.greeness > 0.9) {
          this.$refs.citySquareLeftGreenessBubble.setAttribute(
            "style",
            "left : calc(" + this.greeness * 100 + "% - 52px);"
          );
          this.$refs.citySquareLeftGreenessBubble.classList.add(
            "details-greenery-bubble-high-value"
          );
        } else {
          this.$refs.citySquareLeftGreenessBubble.classList.remove(
            "details-greenery-bubble-high-value"
          );
          if (this.greeness < 0.1) {
            this.$refs.citySquareLeftGreenessBubble.setAttribute(
              "style",
              "left : calc(" +
                (this.greeness + 0.01) * 100 +
                "% - 32px); width : 60px"
            );
          } else if (this.greeness > 0.65) {
            this.$refs.citySquareLeftGreenessBubble.setAttribute(
              "style",
              "left : calc(" + this.greeness * 100 + "% - 40px);"
            );
          } else if (this.greeness > 0.5) {
            this.$refs.citySquareLeftGreenessBubble.setAttribute(
              "style",
              "left : calc(" + this.greeness * 100 + "% - 40px);"
            );
          } else if (this.greeness > 0.35) {
            this.$refs.citySquareLeftGreenessBubble.setAttribute(
              "style",
              "left : calc(" + this.greeness * 100 + "% - 40px);"
            );
          } else {
            this.$refs.citySquareLeftGreenessBubble.setAttribute(
              "style",
              "left : calc(" + this.greeness * 100 + "% - 40px);"
            );
          }
        }
      } else {
        if (this.greeness > 0.9) {
          this.$refs.citySquareLeftGreenessBubble.setAttribute(
            "style",
            "left : calc(" + this.greeness * 100 + "% - 52px);"
          );
          this.$refs.citySquareLeftGreenessBubble.classList.add(
            "details-greenery-bubble-high-value"
          );
        } else {
          this.$refs.citySquareLeftGreenessBubble.classList.remove(
            "details-greenery-bubble-high-value"
          );
          if (this.greeness < 0.1) {
            this.$refs.citySquareLeftGreenessBubble.setAttribute(
              "style",
              "left : calc(" +
                (this.greeness + 0.01) * 100 +
                "% - 32px); width : 60px"
            );
          } else if (this.greeness > 0.65) {
            this.$refs.citySquareLeftGreenessBubble.setAttribute(
              "style",
              "left : calc(" + this.greeness * 100 + "% - 23px);"
            );
          } else if (this.greeness > 0.5) {
            this.$refs.citySquareLeftGreenessBubble.setAttribute(
              "style",
              "left : calc(" + this.greeness * 100 + "% - 23px);"
            );
          } else if (this.greeness > 0.35) {
            this.$refs.citySquareLeftGreenessBubble.setAttribute(
              "style",
              "left : calc(" + this.greeness * 100 + "% - 23px);"
            );
          } else {
            this.$refs.citySquareLeftGreenessBubble.setAttribute(
              "style",
              "left : calc(" + this.greeness * 100 + "% - 23px);"
            );
          }
        }
      }
    },
    // Display popup
    toggleDisplayPopup() {
      this.displayPopup = true;
    },
  },
};
</script>
<style scoped>
.city-square {
  position: relative;
  width: 100%;
  min-height: 50vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-image: url("./background-image/Graphic-lines.svg");
  background: linear-gradient(
    33deg,
    rgba(49, 108, 184, 1) 0%,
    rgba(19, 57, 140, 1) 39%,
    rgba(19, 55, 139, 1) 100%
  );
}

.city-square-left {
  padding: 88px 12% 88px 24%;
}

.map-container-div {
  padding: 60px;
}

@media only screen and (max-width: 1200px) {
  .city-square-left {
    padding: 88px 12% 88px 12%;
  }
}
.city-square-left-title-div {
  display: flex;
  justify-content: space-between;
}

.city-square-left-title-texts {
  color: white;
  margin-left: 64px;
}

.city-square-left-title-title {
  font-weight: bold;
  font-size: 2vw;
  letter-spacing: 4px;
}

.city-square-left-title-subtitle {
  font-size: 1.2vw;
  letter-spacing: inherit;
  color: #7fabe9;
}

.city-square-left-title-subtitle-yellow {
  color: #ffda36;
  font-weight: bold;
}

.city-square-left-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 3vw;
  row-gap: 3vw;
  column-gap: 12%;
}

.city-square-left-grid-right-item {
  position: relative;
  display: flex;
  flex-direction: row;
}
.city-square-left-grid-right-icon {
  margin-right: 16px;
}

.city-square-left-grid-right-item-text {
  position: relative;
}
.city-square-left-grid-right-item-title {
  position: relative;
  font-weight: bold;
  font-size: 1.3vw;
  color: white;
}

.city-square-left-grid-right-item-subtitle {
  position: relative;
  color: #c4e2f3;
  font-size: 0.8vw;
}

.city-square-left-greeness {
  position: relative;
  margin-top: 3vw;
}

.city-square-left-greeness-bubble {
  position: relative;
  width: 80px;
  height: 50px;
  padding: 0px;
  background: #113689;
  border: #8ebf83 solid 2px;
  z-index: 4;
  bottom: 12px;
  transition: all 0.3s ease-in-out;
}

.city-square-left-greeness-bubble::after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 10px 10px 0;
  border-color: #113689 transparent;
  display: block;
  width: 0;
  z-index: 1;
  bottom: -9px;
  left: calc(-10px + 50%);
}

.city-square-left-greeness-bubble::before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 10px 10px 0;
  border-color: #8ebf83 transparent;
  display: block;
  width: 0;
  z-index: 0;
  bottom: -12px;
  left: calc(-10px + 50%);
}

.city-square-left-greeness-bubble-high-value {
  position: relative;
  width: 80px;
  height: 48px;
  padding: 0px;
  background: #113689;
  border-radius: 0px;
  border: #8ebf83 solid 2px;
  z-index: 1;
  bottom: 10px;
  transition: all 0.3s ease-in-out;
}

.city-square-left-greeness-bubble-high-value::after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 10px 10px 0;
  border-color: #113689 transparent;
  display: block;
  width: 0;
  z-index: 2;
  bottom: -10px;
  left: calc(50%);
}

.city-square-left-greeness-bubble-high-value::before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 10px 10px 0;
  border-color: #8ebf83 transparent;
  display: block;
  width: 0;
  z-index: 0;
  bottom: -12px;
  left: calc(50%);
}

.city-square-left-greeness-bubble-percentage {
  position: absolute;
  left: 11px;
  z-index: 3;
  font-size: 35px;
  font-weight: bold;
  color: white;
}

.city-square-left-greeness-bubble-text {
  position: absolute;
  top: -8px;
  line-height: 90%;
  font-size: 20px;
  color: #8ebf83;
  transition: all 0.3s ease-in-out;
  color: white;
}

.city-square-left-greeness-numbers {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  gap: 15px;
  color: white;
}

.city-square-left-greeness-numbers-title {
  position: relative;
  font-size: 1.4vw;
  font-weight: bold;
}

.city-square-left-greeness-button {
  z-index: 1;
  font-size: 0.8vw;
  color: #55abec !important;
  text-decoration: none;
  padding: 4% 6% 4% 6%;
  border: 2px solid #3a7bc5;
  border-radius: 30px;
  text-align: center;
}

.city-square-left-greeness-numbers-subtitle {
  font-size: 0.8vw;
}

.klover-focus-icon-class {
  max-height: 7vw;
  max-width: 7vw;
}

.span-percentage {
  font-size: 15px;
}

@media screen and (max-width: 800px) {
  .city-square {
    display: block;
  }

  .city-square-left-grid {
    row-gap: 26px;
  }

  .city-square-left {
    padding: 10% 5% 5% 5%;
  }

  .klover-focus-icon-class {
    max-height: 20%;
    max-width: 20%;
  }

  .city-square-left-title-texts {
    margin-left: 7%;
  }

  .city-square-left-title-title {
    font-size: 24px;
    letter-spacing: inherit;
  }

  .city-square-left-title-subtitle {
    font-size: 14px;
    letter-spacing: inherit;
  }

  .city-square-left-greeness {
    margin-top: 5%;
  }

  .city-square-left-greeness-bubble-percentage {
    font-size: 20px;
    left: 2px;
  }

  .city-square-left-greeness-bubble {
    height: 32px;
    width: 45px;
    margin-top: 8%;
  }

  .span-percentage {
    font-size: 14px;
  }

  .city-square-left-greeness-numbers-title {
    font-size: 16px;
  }

  .city-square-left-greeness-numbers-subtitle {
    font-size: 14px;
  }

  .city-square-right-greeness-numbers-title {
    text-align: end;
  }

  .city-square-left-grid-right-item-title {
    font-size: 18px;
  }

  .city-square-left-grid-right-item-subtitle {
    font-size: 10px;
  }

  .city-square-left-greeness-button {
    font-size: 10px;
  }

  .map-container-div {
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 15%;
    padding-top: 10%;
  }
}
</style>
