<template>
  <div>
    <!-- if the city name param in URL matches with city data -->
    <div v-if="city" class="city-div">
      <!-- City presentation : first bloc on city page -->
      <CityPresentation
        v-if="city.weather.meanDeviationYearly"
        :is-mobile="isMobile"
        :city="city"
      ></CityPresentation>
      <!-- Focus Klover -->
      <CitySquare
        ref="citySquare"
        :is-mobile="isMobile"
        :city="city"
      ></CitySquare>

      <!-- Action for climate and environment -->
      <CityText :is-mobile="isMobile" :city="city"></CityText>

      <!-- City climate data -->
      <div style="height: 100%">
        <CityWeather2
          v-if="city.weather && city.weather.meanDeviationYearly"
          :city="city"
          :is-mobile="isMobile"
        >
        </CityWeather2>
      </div>

      <!-- Big graph with 3 options : heat, frost and precipitation -->
      <div style="height: 100%">
        <GlobalGraph
          v-if="city.weather"
          :is-mobile="isMobile"
          :city="city"
        ></GlobalGraph>
      </div>

      <!-- If not on mobile, display small text block -->
      <v-row
        v-if="!isMobile"
        class="ma-5 mb-15"
        align="center"
        justify="center"
      >
        <CityKermapData />
      </v-row>

      <!-- Contact block -->
      <div class="city-break-boundaries-div">
        <div class="city-break-boundaries-text-div">
          <span class="city-break-boundaries-title">{{
            $t("city-page.see-more.title")
          }}</span>
          <div class="city-break-boundaries-text">
            {{ $t("city-page.see-more.content") }}
          </div>
          <a class="city-break-boundaries-btn" href="/contact">
            {{ $t("city-page.see-more.button") }}
            <v-icon class="ml-2" color="#11388a">mdi-email-outline</v-icon>
          </a>
        </div>
        <div v-if="!isMobile" class="city-break-boundaries-image-div">
          <v-img
            class="city-break-boundaries-img"
            max-height="200"
            :aspect-ratio="16 / 9"
            :src="require('@/assets/images/contact.jpg')"
          />
        </div>
      </div>

      <!-- City list -->
      <div class="city-more-cities-div">
        <div class="city-more-cities-title-div">
          <span class="city-more-cities-title">{{
            $t("city-page.on-planet.title")
          }}</span>
          <a
            v-if="!isMobile"
            class="city-more-cities-anchor"
            :href="$i18n.locale === 'fr' ? '/classement' : '/featured'"
            >{{ $t("city-page.on-planet.button") }}</a
          >
        </div>
        <div class="city-more-cities-cards mb-5">
          <CityCard
            v-for="(cityElem, i) in moreCities"
            :key="i"
            :is-mobile="isMobile"
            :city="cityElem"
          />
        </div>
        <a
          v-if="isMobile"
          class="city-more-cities-anchor"
          :href="$i18n.locale === 'fr' ? '/classement' : '/featured'"
          >{{ $t("city-page.on-planet.button") }}</a
        >
      </div>
    </div>
    <!-- if the city named param of the URL does not match with the city data-->
    <div v-else class="link-error">
      <p class="link-error-text">
        Unknown city.
        <br />
        <br />Please check the page link or go to
        <a
          class="link-error-anchor"
          :href="$i18n.locale === 'fr' ? '/classement' : '/featured'"
          >Featured page</a
        >
        to know all the cities referenced on Klover
      </p>
    </div>
  </div>
</template>

<script>
import CityPresentation from "@/components/City/CityPresentation";
import CitySquare from "@/components/City/CitySquare";
import CityText from "@/components/City/CityText2";
import CityWeather2 from "@/components/City/CityWeather2";
import GlobalGraph from "@/components/City/graph/GlobalGraph";
import CityCard from "@/components/Description/CityCard";
import kloverData from "@/assets/klover_data.json";
import CityKermapData from "@/components/City/CityKermapData";

import axios from "axios";
export default {
  name: "City",
  components: {
    CityCard,
    CityPresentation,
    CitySquare,
    CityText,
    CityWeather2,
    GlobalGraph,
    CityKermapData,
  },
  // Define meta tags for city page
  metaInfo() {
    var result;
    if (window.location.pathname === "/ville") {
      result = [
        {
          name: "title",
          content: "Klover : végétation urbaine et changement climatique",
        },
        {
          name: "description",
          content:
            "Découvrez la place de la végétation urbaine et les effets du changement climatique dans 24 grandes villes du monde",
        },
        { property: "og:type", content: "website" },
        {
          property: "og:url",
          content: "https://klover.city/explorer",
        },
        {
          property: "og:title",
          content: "Klover : végétation urbaine et changement climatique",
        },
        {
          property: "og:description",
          content:
            "Découvrez la place de la végétation urbaine et les effets du changement climatique dans 24 grandes villes du monde",
        },
        {
          property: "og:image",
          content: "https://klover.city/meta-img/explore-card-fr.jpg",
        },
        { property: "twitter:card", content: "summary_large_image" },
        {
          property: "twitter:url",
          content: "https://klover.city/explorer",
        },
        {
          property: "twitter:title",
          content: "Klover : végétation urbaine et changement climatique",
        },
        {
          property: "twitter:description",
          content:
            "Découvrez la place de la végétation urbaine et les effets du changement climatique dans 24 grandes villes du monde",
        },
        {
          property: "twitter:image",
          content: "https://klover.city/meta-img/explore-card-fr.jpg",
        },
      ];
    } else {
      result = [
        {
          name: "title",
          content: "Klover : urban vegetation and climate change impact",
        },
        {
          name: "description",
          content:
            "Get insights into urban greenness and the effects of climate change in 24 big cities around the world",
        },
        { property: "og:type", content: "website" },
        {
          property: "og:url",
          content: "https://klover.city/explore",
        },
        {
          property: "og:title",
          content: "Klover : urban vegetation and climate change impact",
        },
        {
          property: "og:description",
          content:
            "Get insights into urban greenness and the effects of climate change in 24 big cities around the world",
        },
        {
          property: "og:image",
          content: "https://klover.city/meta-img/explore-card-en.jpg",
        },
        { property: "twitter:card", content: "summary_large_image" },
        {
          property: "twitter:url",
          content: "https://klover.city/explore",
        },
        {
          property: "twitter:title",
          content: "Klover : urban vegetation and climate change impact",
        },
        {
          property: "twitter:description",
          content:
            "Get insights into urban greenness and the effects of climate change in 24 big cities around the world",
        },
        {
          property: "twitter:image",
          content: "https://klover.city/meta-img/explore-card-en.jpg",
        },
      ];
    }
    return {
      meta: result,
    };
  },
  data: function () {
    return {
      // City selected by the user
      city: null,
      // Load kermap data
      data: kloverData,
      // Array to display more cities on city page
      moreCities: [],
      // Check if user is on mobile (false by default)
      isMobile: false,
    };
  },
  async created() {
    // get the cityName of URL param
    const cityName = this.$route.params.name;
    this.city = kloverData[cityName];

    if (this.city) {
      // change the page title
      this.city.name = cityName;
    }
    await this.getWeatherData();
  },
  mounted() {
    if (this.city) {
      this.pickMoreCities();
    }
    // rule checked to define if user is on mobile or not
    this.isMobile = window.innerWidth <= 800;
    // Check the rule everytime the window is resized
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth <= 800;
    });
  },
  methods: {
    // Request our weather data for this particular city
    async getWeatherData() {
      let meanDeviationResponse;
      try {
        // Get data from local Nasa Power api for historical weather information for charts
        meanDeviationResponse = await axios.get(
          process.env.VUE_APP_NASA_POWER_LOCAL_URL +
            this.city.name +
            "/mean_deviation"
        );
        meanDeviationResponse = meanDeviationResponse.data;
        // If not exist, create weather section in our data
        if (!this.city.weather) {
          this.city.weather = {};
        }
        this.city.weather.meanDeviation = meanDeviationResponse;
        this.$forceUpdate();
      } catch (error) {
        console.error(error);
      }
      let meanDeviationYearlyResponse;

      try {
        // Get data from local Nasa Power api for historical weather information for charts
        meanDeviationYearlyResponse = await axios.get(
          process.env.VUE_APP_NASA_POWER_LOCAL_URL +
            this.city.name +
            "/mean_deviation_yearly"
        );
        meanDeviationYearlyResponse = meanDeviationYearlyResponse.data;
        // If not exist, create weather section in our data
        if (!this.city.weather) {
          this.city.weather = {};
        }
        this.city.weather.meanDeviationYearly = meanDeviationYearlyResponse;
        this.$forceUpdate();
      } catch (error) {
        console.error(error);
      }
    },
    // pick random cities for the MoreCities section
    async pickMoreCities() {
      let numberOfCitiesPicked = 0;
      let randomCitiesIndex = [];
      let meanDeviationResponse;
      const cityArray = Object.entries(this.data);
      // while 3 random and unique city are not picked
      while (numberOfCitiesPicked < 3) {
        // generate the random index of the city
        let index = Math.floor(Math.random() * cityArray.length);
        // if the index is not already picked, and is not the index of the city of the page
        if (
          randomCitiesIndex.indexOf(index) == -1 &&
          cityArray[index][1] != this.city
        ) {
          // push the random index on the already random picked index list
          randomCitiesIndex.push(index);

          try {
            // Get data from local Nasa Power api for historical weather information for charts
            meanDeviationResponse = await axios.get(
              process.env.VUE_APP_NASA_POWER_LOCAL_URL +
                cityArray[index][0] +
                "/mean_deviation_yearly"
            );
            meanDeviationResponse = meanDeviationResponse.data;
            if (!cityArray[index][1].weather) {
              cityArray[index][1].weather = {};
            }
            cityArray[index][1].weather.meanDeviationYearly =
              meanDeviationResponse;
          } catch (error) {
            console.error(error);
          }
          // push the city of this index to the moreCities list
          this.moreCities.push(cityArray[index]);
          // increment the number of random cities picked
          numberOfCitiesPicked++;
        }
      }
    },
  },
};
</script>

<style scoped>
.city-div {
  position: relative;
}

.city-map {
  position: relative;
  width: 100%;
  height: 79vh;
}

.city-margin-div {
  position: relative;
  margin: 0% 12% 5% 12%;
}

@media only screen and (max-width: 800px) {
  .city-margin-div {
    position: relative;
    margin: 1% 3% 5% 3%;
  }

  .city-text-container {
    height: 100%;
  }

  .city-more-cities-title {
    font-size: 24px;
  }

  .city-more-cities-anchor {
    font-size: 16px;
  }
}
</style>
