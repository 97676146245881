<template>
  <div class="city-presentation">
    <!-- City img -->
    <v-img
      class="city-presentation-img"
      :src="require('@/assets/images/' + city.name + '.jpg')"
      :lazy-src="require('@/assets/images/' + city.name + '.jpg')"
    ></v-img>

    <div class="city-presentation-content">
      <!-- <a
        class="city-presentation-content-anchor"
        :href="$i18n.locale === 'fr' ? '/classement' : '/featured'"
      >
        <v-icon
          v-if="!isMobile"
          size="1.25vw"
          class="city-presentation-content-anchor-icon"
          color="#113689"
          >mdi-dots-grid</v-icon
        ><v-icon
          v-if="isMobile"
          size="20px"
          class="city-presentation-content-anchor-icon"
          color="#113689"
          >mdi-dots-grid</v-icon
        >{{ $t("city-page.city-presentation.all-cities") }}
      </a> -->
      <div class="city-presentation-content-grid">
        <!-- left section -->
        <div class="city-presentation-content-grid-left">
          <h2 class="city-presentation-content-grid-left-title">
            {{ $t("city." + city.name + ".name") }}
          </h2>
          <p class="city-presentation-content-grid-left-subtitle">
            {{ $t("city." + city.name + ".country") }}
          </p>
          <p
            v-if="!isMobile"
            class="city-presentation-content-grid-left-paragraph"
          >
            {{ $t("city." + city.name + ".presentation") }}
          </p>
          <!-- On mobile : add "Read More" button -->
          <text-container
            v-if="isMobile"
            :text="this.$t('city.' + city.name + '.presentation')"
          />
        </div>

        <!-- Right section -->
        <v-card
          class="city-presentation-card-class"
          outlined
          color="#f4f3f1"
          :elevation="isMobile ? 15 : 0"
        >
          <v-container>
            <v-card-title class="city-presentation-card-title">
              <h1 class="city-presentation-card-title">
                {{ $t("city." + city.name + ".name")
                }}{{ $t("city-page.city-presentation.green-city") }}
              </h1>
            </v-card-title>
            <v-card-text class="city-presentation-card-text">
              <p>
                {{ $t("city-page.city-presentation.numbers1") }}
                {{ $t("city." + city.name + ".name")
                }}{{ $t("city-page.city-presentation.numbers2") }}
              </p>
            </v-card-text>
            <div class="city-presentation-content-grid-right">
              <div class="city-presentation-content-grid-right-item">
                <v-row dense>
                  <v-col cols="3">
                    <v-icon v-if="!isMobile" size="3vw"
                      >$vuetify.icon.city_population</v-icon
                    >
                    <v-icon v-if="isMobile" size="35px"
                      >$vuetify.icon.city_population</v-icon
                    >
                  </v-col>
                  <v-col>
                    <p class="city-presentation-content-grid-right-item-title">
                      {{ city.PopTot.toFixed(2) }} M
                    </p>
                    <p class="city-presentation-content-grid-right-item-text">
                      {{ $t("city-page.city-presentation.statistics.stat1") }}
                    </p>
                  </v-col>
                </v-row>
              </div>
              <div class="city-presentation-content-grid-right-item">
                <v-row dense>
                  <v-col cols="3">
                    <v-icon v-if="!isMobile" size="3vw"
                      >$vuetify.icon.klover_score</v-icon
                    >
                    <v-icon v-if="isMobile" size="35px"
                      >$vuetify.icon.klover_score</v-icon
                    >
                  </v-col>
                  <v-col cols="7">
                    <p class="city-presentation-content-grid-right-item-title">
                      {{ Math.round(city.PercVeg) }}
                      <span style="font-size: 16px">/100</span>
                    </p>
                    <p class="city-presentation-content-grid-right-item-text">
                      {{ $t("city-page.city-presentation.statistics.stat3") }}
                    </p>
                  </v-col>
                  <v-col>
                    <info-btn v-if="!isMobile" />
                  </v-col>
                </v-row>
              </div>
              <div class="city-presentation-content-grid-right-item">
                <v-row dense>
                  <v-col cols="3">
                    <v-icon v-if="!isMobile" size="3vw"
                      >$vuetify.icon.featured_cities_yellow</v-icon
                    >
                    <v-icon v-if="isMobile" size="35px"
                      >$vuetify.icon.featured_cities_yellow</v-icon
                    >
                  </v-col>
                  <v-col>
                    <p class="city-presentation-content-grid-right-item-title">
                      {{ Math.round(city.Tot) }} km&#xB2;
                    </p>
                    <p class="city-presentation-content-grid-right-item-text">
                      {{ $t("city-page.city-presentation.statistics.stat2") }}
                    </p>
                  </v-col>
                </v-row>
              </div>
              <div
                class="city-presentation-content-grid-right-item"
                @click="scrollToSection('city-weather')"
              >
                <v-row dense>
                  <v-col cols="3">
                    <v-icon v-if="!isMobile" size="3vw"
                      >$vuetify.icon.mean_deviation</v-icon
                    >
                    <v-icon v-if="isMobile" size="35px"
                      >$vuetify.icon.mean_deviation</v-icon
                    >
                  </v-col>
                  <v-col :cols="isMobile ? '9' : '7'">
                    <p class="city-presentation-content-grid-right-item-title">
                      {{
                        (city.weather.meanDeviationYearly.C[
                          city.weather.meanDeviationYearly.C.length - 1
                        ] >= 0
                          ? "+"
                          : "") +
                        $n(
                          city.weather.meanDeviationYearly.C[
                            city.weather.meanDeviationYearly.C.length - 1
                          ].toFixed(2)
                        ) +
                        "°C"
                      }}
                    </p>
                    <p class="city-presentation-content-grid-right-item-text">
                      {{ $t("city-page.city-presentation.statistics.stat4")
                      }}{{ currentDate }}
                    </p>
                  </v-col>
                  <v-col>
                    <info-btn v-if="!isMobile" :temperature="true" />
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-container>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import InfoBtn from "@/components/Description/information/InfoBtn";
import TextContainer from "@/components/Page/TextContainer";

export default {
  name: "CityPresentation",
  components: {
    InfoBtn,
    TextContainer,
  },
  props: {
    city: {
      type: Object,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      // Get current year
      currentDate: new Date().getFullYear(),
    };
  },
  methods: {
    scrollToSection(id) {
      let element = document.getElementById(id);
      element.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style>
.city-presentation {
  position: relative;
  height: 45vw;
}

.city-presentation-img {
  position: absolute;
  width: 100%;
  height: 60%;
}

.city-presentation-content {
  position: relative;
  height: 100%;
  background: linear-gradient(#ffffff00 20%, #f4f3f1 45%);
}

.city-presentation-content-anchor {
  position: absolute;
  left: 12%;
  top: 16px;
  color: #113689 !important;
  text-decoration: none;
  font-size: 0.8vw;
  z-index: 2;
}

.city-presentation-content-anchor-icon {
  position: relative;
  bottom: 3px;
  margin-right: 6px;
}

.city-presentation-content-grid {
  position: absolute;
  top: 45%;
  display: grid;
  grid-template-columns: 2fr 3fr;
  column-gap: 6%;
  padding: 0 12% 0 12%;
}

.city-presentation-content-grid-left-title {
  font-family: "Albra Bold";
  font-size: 3.2vw;
  letter-spacing: 4px;
  line-height: 90%;
  font-display: swap;
}

.city-presentation-content-grid-left-subtitle {
  font-size: 1.8vw;
  padding-bottom: 12px;
}

.city-presentation-content-grid-left-paragraph {
  font-size: 0.9vw;
}

.city-presentation-content-grid-right {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 5%;
  row-gap: 3.2vw;
}

.city-presentation-card-title {
  color: #113689;
  font-weight: bold;
  font-size: 1.3vw;
}

.city-presentation-card-text {
  color: #113689;
  margin-bottom: 4vw;
  font-size: 1vw;
}

.city-presentation-content-grid-right-item {
  position: relative;
  display: flex;
  flex-direction: column;
}

.city-presentation-content-grid-right-item-title {
  position: relative;
  font-weight: bold;
  font-size: 1.3vw;
  margin-top: 18px;
  color: #113689;
}

.city-presentation-content-grid-right-item-text {
  position: relative;
  color: #6992a3;
  font-size: 0.85vw;
}

.city-presentation-card-class {
  position: relative;
  bottom: 120px;
  border-radius: 10px;
  max-width: 45vw;
}

@media screen and (max-width: 800px) {
  .city-presentation-content-grid {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: inherit;
    display: block;
    position: relative;
    padding-top: 35vh;
    padding-right: 5%;
    padding-left: 5%;
  }

  .city-presentation-img {
    height: 30%;
  }

  .city-presentation-content {
    background: linear-gradient(#ffffff00 5%, #f4f3f1 25%);
  }

  .city-presentation {
    height: 100%;
  }

  .city-presentation-card-class {
    max-width: inherit;
    bottom: inherit;
  }

  .city-presentation-card-class {
    min-width: 90vw;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  @media screen and (orientation: portrait) {
    .city-presentation-card-class {
      min-width: 90vw;
      margin-top: 20%;
      margin-bottom: 20%;
    }
  }

  .city-presentation-card-title {
    font-size: 16px;
  }

  .city-presentation-card-text {
    font-size: 12px;
  }

  .city-presentation-content-grid-right {
    display: grid;
    grid-template-columns: 3fr 3fr;
    row-gap: 0px;
    column-gap: 0px;
  }

  .city-presentation-content-grid-right-item-title {
    font-size: 17px;
  }

  .city-presentation-content-grid-right-item-text {
    font-size: 14px;
  }

  .city-presentation-content-grid-right-item {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .city-presentation-content-anchor {
    font-size: 14px;
    left: 2%;
  }

  .city-presentation-content-grid-left-title {
    font-size: 24px;
  }

  .city-presentation-content-grid-left-subtitle {
    font-size: 18px;
  }

  .city-presentation-content-grid-left-paragraph {
    font-size: 14px;
  }
}
</style>
