<template>
  <!-- container where is displayed the map -->
  <div id="map" class="map-main-div">
    <!-- Toolbar to warn user to click on the map to activate controls -->
    <v-toolbar v-if="displayToolbar" dense style="z-index: 5">
      <p
        v-if="!isMobile"
        style="width: 100%; font-size: 14px; text-align: center"
      >
        {{ $t("city-page.city-square.map-legend.toolbar") }}
      </p>
    </v-toolbar>
    <!-- Legend container -->
    <div class="map-legend-div">
      <v-container fill-height fluid :class="isMobile ? '' : 'pa-5'">
        <v-row no-gutters dense>
          <v-col>
            <v-btn
              small
              text
              color="#117c11"
              style="text-transform: inherit; text-indent: inherit"
              @click="toggleTreeLayer()"
            >
              <v-icon v-if="!isMobile" size="1.3vw"
                >$vuetify.icon.tree_legend</v-icon
              >
              <v-icon v-if="isMobile" size="13px"
                >$vuetify.icon.tree_legend</v-icon
              >
              <p class="pl-2 legend-button-text">
                {{ $t("city-page.city-square.map-legend.legend11") }} <br />{{
                  $t("city-page.city-square.map-legend.legend12")
                }}
              </p>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              small
              text
              color="#5cad27"
              style="text-transform: inherit; text-indent: inherit"
              @click="toggleGrassLayer()"
            >
              <v-icon v-if="!isMobile" size="1.3vw"
                >$vuetify.icon.grass_area</v-icon
              >
              <v-icon v-if="isMobile" size="13px"
                >$vuetify.icon.grass_area</v-icon
              >
              <p class="pl-2 legend-button-text">
                {{ $t("city-page.city-square.map-legend.legend21") }} <br />{{
                  $t("city-page.city-square.map-legend.legend22")
                }}
              </p>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              class="density-btn"
              small
              text
              color="#923450"
              style="text-transform: inherit; text-indent: inherit"
              @click="togglePopulationDensityLayer()"
            >
              <v-icon v-if="!isMobile" size="1.3vw"
                >$vuetify.icon.density</v-icon
              >
              <v-icon v-if="isMobile" size="13px">$vuetify.icon.density</v-icon>
              <p class="pl-2 legend-button-text">
                {{ $t("city-page.city-square.map-legend.legend31") }} <br />{{
                  $t("city-page.city-square.map-legend.legend32")
                }}
              </p>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import maplibregl from "maplibre-gl";
export default {
  name: "Map",
  props: {
    city: {
      type: Object,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      map: null,
      displayToolbar: true,
    };
  },
  mounted() {
    this.createMap();
  },
  methods: {
    // init and create MapLibre JS map
    createMap() {
      let self = this;
      // Create map
      this.map = new maplibregl.Map({
        container: "map",
        style: require("@/assets/basemaps/city.json"),
        center: [this.city.center[1], this.city.center[0]],
        zoom: 13,
        maxBounds: this.city.bounds,
      });

      // Add zoom buttons
      this.map.addControl(
        new maplibregl.NavigationControl({ showCompass: false }),
        "bottom-right"
      );
      // Disable dragRotate control on map
      this.map.dragRotate.disable();

      // load vegetation layers
      this.map.on("load", function () {
        self.map.resize();
        let updateStyle = self.map.getStyle();
        // Load density layer
        updateStyle.sources.kermapDensity.tiles = [
          process.env.VUE_APP_MAPSERVER_URL +
            self.city.name +
            "PopDensity.map&mode=tile&tilemode=gmap&tile={x}+{y}+{z}",
        ];

        // Load tree layer
        updateStyle.sources.kermap.tiles = [
          process.env.VUE_APP_MAPSERVER_URL +
            self.city.name +
            ".map&mode=tile&tilemode=gmap&tile={x}+{y}+{z}",
        ];

        // Load grass layer
        updateStyle.sources.kermapGrass.tiles = [
          process.env.VUE_APP_MAPSERVER_URL +
            self.city.name +
            "Grass.map&mode=tile&tilemode=gmap&tile={x}+{y}+{z}",
        ];

        // Update map style
        self.map.setStyle(updateStyle);
        self.map.setLayoutProperty("kermapLayer", "visibility", "visible");
        self.map.setLayoutProperty("kermapLayerGrass", "visibility", "visible");
        self.map.resize();
        self.map.scrollZoom.disable();
      });

      // Activate control on map if user click on the canvas and if the control is still disabled
      this.map.on("click", () => {
        if (!this.map.scrollZoom.isEnabled()) {
          this.map.scrollZoom.enable();
          this.displayToolbar = false;
        }
      });
    },
    // Show or Hide grass layer
    toggleGrassLayer() {
      if (
        this.map.getLayoutProperty("kermapLayerGrass", "visibility") ===
        "visible"
      ) {
        this.map.setLayoutProperty("kermapLayerGrass", "visibility", "none");
      } else {
        this.map.setLayoutProperty("kermapLayerGrass", "visibility", "visible");
      }
    },
    // Show or Hide tree layer
    toggleTreeLayer() {
      if (
        this.map.getLayoutProperty("kermapLayer", "visibility") === "visible"
      ) {
        this.map.setLayoutProperty("kermapLayer", "visibility", "none");
      } else {
        this.map.setLayoutProperty("kermapLayer", "visibility", "visible");
      }
    },
    // Show or Hide density layer
    togglePopulationDensityLayer() {
      let self = this;

      if (
        self.map.getLayoutProperty("kermapDensityLayer", "visibility") ===
        "visible"
      ) {
        self.map.setLayoutProperty("kermapDensityLayer", "visibility", "none");
      } else {
        self.map.setLayoutProperty(
          "kermapDensityLayer",
          "visibility",
          "visible"
        );
      }
    },
  },
};
</script>

<style>
.maplibregl-ctrl-attrib {
  display: none !important;
}

@import url("https://unpkg.com/maplibre-gl@1.15.2/dist/maplibre-gl.css");
.mapboxgl-canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.city-map-population-button {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 1;
  font-size: medium;
  color: #113689 !important;
  background-color: #ffffff;
  text-decoration: none;
  padding: 15px 20px 15px 20px;
  border: 2px solid #ffda36;
  border-radius: 30px;
}

.city-map-population-active {
  background-color: #ffda36;
}

.map-legend-div {
  background-color: white;
  position: absolute;
  bottom: 0;
  z-index: 3;
  border-radius: 0 15px 0 0;
}

.map-main-div {
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 46vw;
  border-radius: 10px;
  margin-top: 1vw;
}

.legend-button-text {
  font-weight: 0.6vw;
  text-align: left;
}

@media screen and (max-width: 800px) {
  .map-main-div {
    height: 50vh;
    width: 95vw;
    max-height: inherit;
    margin-top: inherit;
    border-radius: 10px;
  }

  @media screen and (orientation: landscape) {
    .map-main-div {
      height: 75vh;
      width: 93vw;
    }
  }

  .map-legend-div {
    width: 100%;
    height: 45px;
    border-radius: inherit;
    position: relative;
    border-radius: 0;
  }

  .legend-button-text {
    font-size: 10px;
  }

  .density-btn {
    margin-top: 0;
  }

  .v-btn:not(.v-btn--round).v-size--small {
    padding: 0;
  }
}
</style>
