<template>
  <div>
    <p v-if="!longText" class="text-content">{{ text.slice(0, 150) }}...</p>
    <v-btn
      v-if="!longText"
      style="text-transform: inherit; text-decoration: underline; float: right"
      text
      small
      color="#0c318a"
      @click="toggleLongText()"
      >Read more</v-btn
    >
    <p v-if="longText" class="text-content">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: "TextContainer",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      longText: false,
    };
  },
  methods: {
    toggleLongText() {
      this.longText = true;
    },
  },
};
</script>

<style scoped>
.text-content {
  font-size: 0.9vw;
  white-space: pre-line;
  max-width: 25vw;
}

@media screen and (max-width: 800px) {
  .text-content {
    font-size: 14px;
    max-width: 300px;
  }
}
</style>
