<template>
  <!-- Big graphic with key number -->
  <v-container fill-height fluid>
    <v-row class="">
      <div class="global-graph-first-div">
        <!-- title -->
        <p class="global-graph-title">
          {{ $t("city-page.city-weather.global-graph.title1")
          }}{{ $t("city." + city.name + ".name")
          }}{{ $t("city-page.city-weather.global-graph.title2")
          }}{{ currentDate
          }}{{ $t("city-page.city-weather.global-graph.title3") }}
        </p>
      </div>
    </v-row>
    <!-- Graph button type -->
    <v-row class="ma-0" justify="center">
      <div class="global-graph-first-row-first-div">
        <button
          id="city-weather-chart-button-heat"
          class="city-weather-chart-button city-weather-chart-button-active"
          @click="toggleExtremeChart($event)"
        >
          {{ $t("city-page.city-weather.global-graph.button.heat") }}
        </button>
        <button
          id="city-weather-chart-button-prec"
          class="city-weather-chart-button"
          @click="toggleExtremeChart($event)"
        >
          {{ $t("city-page.city-weather.global-graph.button.prec") }}
        </button>
        <button
          id="city-weather-chart-button-frost"
          class="city-weather-chart-button"
          @click="toggleExtremeChart($event)"
        >
          {{ $t("city-page.city-weather.global-graph.button.frost") }}
        </button>
      </div>
    </v-row>
    <v-row class="third-row-class">
      <div class="city-weather-chart-div">
        <v-row
          :no-gutters="isMobile ? true : false"
          align="center"
          :justify="!isMobile ? 'center' : ''"
          align-content="center"
        >
          <v-col :class="isMobile ? 'pa-0' : ''" :cols="isMobile ? '12' : ''">
            <!-- Global graph chart -->
            <canvas ref="extremeChart" class="city-weather-chart"></canvas>
            <!-- Legend container -->
            <v-row class="mt-3 mb-3">
              <v-col>
                <button
                  id="city-weather-legend-btn-81"
                  class="city-weather-legend-btn"
                  @click="toggleEntry('city-weather-legend-btn-81')"
                >
                  <v-icon class="mr-1 pb-1" :color="legendColor[0]"
                    >mdi-circle</v-icon
                  >
                  <span>1981-2010</span>
                </button>
                <button
                  id="city-weather-legend-btn-11"
                  class="
                    city-weather-legend-btn city-weather-legend-btn-inactive
                  "
                  @click="toggleEntry('city-weather-legend-btn-11')"
                >
                  <v-icon class="mr-1 pb-1" :color="legendColor[1]"
                    >mdi-circle</v-icon
                  >
                  <span>2011-2020</span>
                </button>
                <button
                  id="city-weather-legend-btn-21"
                  class="city-weather-legend-btn"
                  @click="toggleEntry('city-weather-legend-btn-21')"
                >
                  <v-icon class="mr-1 pb-1" :color="legendColor[2]"
                    >mdi-circle</v-icon
                  >
                  <span>{{ currentDate }}</span>
                </button>
              </v-col>
            </v-row>
          </v-col>

          <!-- Numbers on map -->
          <v-col
            v-if="graphNumber && activeTypeWeatherChart === 'heat'"
            :class="isMobile ? 'pa-0' : ''"
            :cols="isMobile ? '12' : ''"
          >
            <v-row justify="center" align="center">
              <v-col>
                <p>
                  <span class="global-graph-desc-legends">{{
                    $t("city-page.city-weather.global-graph.text.legend1")
                  }}</span>
                </p>
              </v-col>
              <v-col>
                <p>
                  <span class="global-graph-desc-legends"
                    >{{
                      $t("city-page.city-weather.global-graph.text.legend2")
                    }}
                    {{ currentDate }}</span
                  >
                </p>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col :class="isMobile ? 'pr-0' : ''">
                <h2 class="global-graph-desc-number">
                  <span class="global-graph-desc-unit">{{
                    city.weather.graphNumbers.Historique_anormalement_chaud
                      .nb_jours
                  }}</span>
                  {{ $t("city-page.city-weather.global-graph.text.day") }}
                </h2>
                <p class="global-graph-desc-text">
                  {{
                    $t(
                      "city-page.city-weather.global-graph.text.heat.old.average"
                    )
                  }}
                </p>
              </v-col>
              <v-col :class="isMobile ? 'pr-0' : ''">
                <h2 class="global-graph-desc-number">
                  <span class="global-graph-desc-unit">{{
                    city.weather.graphNumbers.En_cours_anormalement_chaud
                      .nb_jours
                  }}</span>
                  {{ $t("city-page.city-weather.global-graph.text.day") }}
                </h2>
                <p class="global-graph-desc-text">
                  {{
                    $t(
                      "city-page.city-weather.global-graph.text.heat.new.average"
                    )
                  }}
                </p>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col :class="isMobile ? 'pr-0' : ''">
                <h2 class="global-graph-desc-number">
                  <span class="global-graph-desc-unit">{{
                    city.weather.records.max_histo.values
                  }}</span>
                  °C
                </h2>
                <p class="global-graph-desc-text">
                  {{
                    $t(
                      "city-page.city-weather.global-graph.text.heat.old.record"
                    )
                  }}
                  {{ city.weather.records.date_max_histo.values }}
                </p>
              </v-col>
              <v-col :class="isMobile ? 'pr-0' : ''">
                <h2 class="global-graph-desc-number">
                  <span class="global-graph-desc-unit">{{
                    city.weather.records.max_en_cours.values
                  }}</span>
                  °C
                </h2>
                <p class="global-graph-desc-text">
                  {{
                    $t(
                      "city-page.city-weather.global-graph.text.heat.new.record"
                    )
                  }}
                  {{ city.weather.records.date_max_en_cours.values }}
                </p>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            v-if="!isMobile && graphNumber && activeTypeWeatherChart === 'prec'"
            :class="isMobile ? 'pa-0' : ''"
            :cols="isMobile ? '12' : ''"
          >
            <v-row justify="center" align="center">
              <v-col>
                <p>
                  <span class="global-graph-desc-legends">{{
                    $t("city-page.city-weather.global-graph.text.legend1")
                  }}</span>
                </p>
              </v-col>
              <v-col>
                <p>
                  <span class="global-graph-desc-legends"
                    >{{
                      $t("city-page.city-weather.global-graph.text.legend2")
                    }}
                    {{ currentDate }}</span
                  >
                </p>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col :class="isMobile ? 'pr-0' : ''">
                <h2 class="global-graph-desc-number">
                  <span class="global-graph-desc-unit">{{
                    city.weather.graphNumbers.Historique_jour_pluie.nb_jours
                  }}</span>
                  {{ $t("city-page.city-weather.global-graph.text.day") }}
                </h2>
                <p class="global-graph-desc-text">
                  {{
                    $t(
                      "city-page.city-weather.global-graph.text.prec.old.average"
                    )
                  }}
                </p>
              </v-col>
              <v-col :class="isMobile ? 'pr-0' : ''">
                <h2 class="global-graph-desc-number">
                  <span class="global-graph-desc-unit">{{
                    city.weather.graphNumbers.En_cours_jour_pluis.nb_jours
                  }}</span>
                  {{ $t("city-page.city-weather.global-graph.text.day") }}
                </h2>
                <p class="global-graph-desc-text">
                  {{
                    $t(
                      "city-page.city-weather.global-graph.text.prec.new.average"
                    )
                  }}
                </p>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col :class="isMobile ? 'pr-0' : ''">
                <h2 class="global-graph-desc-number">
                  <span class="global-graph-desc-unit">{{
                    city.weather.records.pluie_max_histo.values
                  }}</span>
                  mm
                </h2>
                <p class="global-graph-desc-text">
                  {{
                    $t(
                      "city-page.city-weather.global-graph.text.prec.old.record"
                    )
                  }}
                  {{ city.weather.records.date_pluis_histo.values }}
                </p>
              </v-col>
              <v-col :class="isMobile ? 'pr-0' : ''">
                <h2 class="global-graph-desc-number">
                  <span class="global-graph-desc-unit">{{
                    city.weather.records.pluie_max_en_cours.values
                  }}</span>
                  mm
                </h2>
                <p class="global-graph-desc-text">
                  {{
                    $t(
                      "city-page.city-weather.global-graph.text.prec.new.record"
                    )
                  }}
                  {{ city.weather.records.date_pluie_en_cours.values }}
                </p>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            v-if="
              !isMobile && graphNumber && activeTypeWeatherChart === 'frost'
            "
            :class="isMobile ? 'pa-0' : ''"
            :cols="isMobile ? '12' : ''"
          >
            <v-row justify="center" align="center">
              <v-col>
                <p>
                  <span class="global-graph-desc-legends">{{
                    $t("city-page.city-weather.global-graph.text.legend1")
                  }}</span>
                </p>
              </v-col>
              <v-col>
                <p>
                  <span class="global-graph-desc-legends"
                    >{{
                      $t("city-page.city-weather.global-graph.text.legend2")
                    }}
                    {{ currentDate }}</span
                  >
                </p>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col :class="isMobile ? 'pr-0' : ''">
                <h2 class="global-graph-desc-number">
                  <span class="global-graph-desc-unit">{{
                    city.weather.graphNumbers.Historique_gel.nb_jours
                  }}</span>
                  {{ $t("city-page.city-weather.global-graph.text.day") }}
                </h2>
                <p class="global-graph-desc-text">
                  {{
                    $t(
                      "city-page.city-weather.global-graph.text.frost.old.average"
                    )
                  }}
                </p>
              </v-col>
              <v-col :class="isMobile ? 'pr-0' : ''">
                <h2 class="global-graph-desc-number">
                  <span class="global-graph-desc-unit">{{
                    city.weather.graphNumbers.En_cours_gel.nb_jours
                  }}</span>
                  {{ $t("city-page.city-weather.global-graph.text.day") }}
                </h2>
                <p class="global-graph-desc-text">
                  {{
                    $t(
                      "city-page.city-weather.global-graph.text.frost.new.average"
                    )
                  }}
                </p>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col :class="isMobile ? 'pr-0' : ''">
                <h2 class="global-graph-desc-number">
                  <span class="global-graph-desc-unit">{{
                    city.weather.records.min_histo.values
                  }}</span>
                  °C
                </h2>
                <p class="global-graph-desc-text">
                  {{
                    $t(
                      "city-page.city-weather.global-graph.text.frost.old.record"
                    )
                  }}
                  {{ city.weather.records.date_min_histo.values }}
                </p>
              </v-col>
              <v-col :class="isMobile ? 'pr-0' : ''">
                <h2 class="global-graph-desc-number">
                  <span class="global-graph-desc-unit">{{
                    city.weather.records.min_en_cours.values
                  }}</span>
                  °C
                </h2>
                <p class="global-graph-desc-text">
                  {{
                    $t(
                      "city-page.city-weather.global-graph.text.frost.new.record"
                    )
                  }}
                  {{ city.weather.records.date_min_en_cours.values }}
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import Chart from "chart.js/auto";
import axios from "axios";

export default {
  name: "GlobalGraph",
  props: {
    city: {
      required: true,
      type: Object,
    },
    isMobile: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTypeWeatherChart: "heat",
      legendColor: ["#fcc850", "#ea9c4a", "#8b2845"],
      extremeChart: null,
      currentDate: new Date().getFullYear(),
      graphNumber: false,
    };
  },
  async mounted() {
    await this.loadMonthlyData();
    this.addExtremeChart();
    await this.loadGraphNumbers();
    await this.getRecords();
  },
  methods: {
    // Load Monthly data
    async loadMonthlyData() {
      let nPMonthlyResponse;
      try {
        // Get data from local Nasa Power api for historical weather information for charts
        nPMonthlyResponse = await axios.get(
          process.env.VUE_APP_NASA_POWER_LOCAL_URL + this.city.name + "/monthly"
        );
        nPMonthlyResponse = nPMonthlyResponse.data;
        if (!this.city.weather) {
          this.city.weather = {};
        }
        this.city.weather.monthly = nPMonthlyResponse;
      } catch (error) {
        console.error(error);
      }
    },
    // Create chartjs chart
    addExtremeChart() {
      if (this.extremeChart) {
        this.extremeChart.destroy();
      }
      var self = this;
      Chart.defaults.font.family = "BR Candor";
      this.extremeChart = new Chart(this.$refs.extremeChart, {
        type: "bar",
        data: {
          labels: this.$t("city-page.weather.months"),
          datasets: [
            {
              label: "Nombre de jours de gel 1981-2010",
              backgroundColor: "#c4e2f3",
              backgroundColorHover: "#c4e2f3",
              barPercentage: 0.6,
              borderRadius: 25,
              data: this.city.weather.monthly.gel_30,
              hidden: true,
            },
            {
              label: "Nombre de jours de gel 2011-2020",
              backgroundColor: "#6392c8",
              backgroundColorHover: "#6392c8",
              barPercentage: 0.6,
              borderRadius: 25,
              data: this.city.weather.monthly.gel_10,
              hidden: true,
            },
            {
              label: "Nombre de jours de gel " + this.currentDate,
              backgroundColor: "#113689",
              backgroundColorHover: "#113689",
              barPercentage: 0.6,
              borderRadius: 25,
              data: this.city.weather.monthly.gel_2021,
              hidden: true,
            },
            {
              label: "Nombre de jours de chaleur anormale 1981-2010",
              backgroundColor: "#fcc850",
              backgroundColorHover: "#fcc850",
              barPercentage: 0.6,
              borderRadius: 25,
              data: this.city.weather.monthly.chaleur_30,
            },
            {
              label: "Nombre de jours de chaleur anormale 2011-2020",
              backgroundColor: "#ea9c4a",
              backgroundColorHover: "#ea9c4a",
              barPercentage: 0.6,
              borderRadius: 25,
              data: this.city.weather.monthly.chaleur_10,
              hidden: true,
            },
            {
              label: "Nombre de jours de chaleur anormale " + this.currentDate,
              backgroundColor: "#8b2845",
              backgroundColorHover: "#8b2845",
              barPercentage: 0.6,
              borderRadius: 25,
              data: this.city.weather.monthly.chaleur_2021,
            },
            {
              label: "Moyenne des précipitations 1981-2010",
              backgroundColor: "#c4e2f3",
              backgroundColorHover: "#3e95cd",
              barPercentage: 0.6,
              borderRadius: 25,
              data: this.city.weather.monthly.PREC_M_30,
              hidden: true,
            },
            {
              label: "Moyenne des précipitations 2011-2020",
              backgroundColor: "#588cc2",
              backgroundColorHover: "#3e95cd",
              barPercentage: 0.6,
              borderRadius: 25,
              data: this.city.weather.monthly.PREC_M_10,
              hidden: true,
            },
            {
              label: "Précipitations " + this.currentDate,
              backgroundColor: "#113689",
              backgroundColorHover: "#113689",
              barPercentage: 0.6,
              borderRadius: 25,
              data: this.city.weather.monthly.PREC_2021,
              hidden: true,
              tension: 0.25,

              transitions: {
                show: {
                  animations: {
                    duration: 1000,
                    easing: "easeOutCubic",
                    y: {
                      from: 500,
                    },
                  },
                },
                hide: {
                  animations: {
                    duration: 1000,
                    easing: "easeOnCubic",
                    y: {
                      to: 500,
                    },
                  },
                },
              },
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
              position: "top",
              labels: {
                font: {
                  size: 14,
                  family: "BR Candor",
                },

                filter: function (item) {
                  return (
                    item.datasetIndex !== 0 &&
                    item.datasetIndex !== 1 &&
                    item.datasetIndex !== 2 &&
                    item.datasetIndex !== 6 &&
                    item.datasetIndex !== 7 &&
                    item.datasetIndex !== 8
                  );
                },
              },
            },
            tooltip: {
              enabled: true,
              mode: "index",
              position: "nearest",
              backgroundColor: "rgb(255,255,255,0.9)",
              titleColor: "rgb(0,0,0)",
              bodyColor: "rgb(0,0,0)",
              bodySpacing: 4,
              caretPadding: 5,
              borderWidth: 0.7,
              borderColor: "rgba(217, 217, 217)",
              callbacks: {
                label: function (context) {
                  var resultString;
                  if (self.$i18n.locale === "fr") {
                    if (self.activeTypeWeatherChart === "prec") {
                      resultString =
                        context.dataset.label +
                        " : " +
                        Math.round(context.raw) +
                        "mm";
                    } else {
                      resultString =
                        context.dataset.label + " : " + Math.round(context.raw);
                    }
                  } else {
                    if (self.activeTypeWeatherChart === "heat") {
                      if (
                        context.dataset.label ===
                        "Nombre de jours de chaleur anormale 2011-2020"
                      ) {
                        resultString =
                          self.$t(
                            "city-page.city-weather.global-graph.text.heat.legend2"
                          ) +
                          " : " +
                          Math.round(context.raw);
                      } else if (
                        context.dataset.label ===
                        "Nombre de jours de chaleur anormale 1981-2010"
                      ) {
                        resultString =
                          self.$t(
                            "city-page.city-weather.global-graph.text.heat.legend1"
                          ) +
                          " : " +
                          Math.round(context.raw);
                      } else {
                        resultString =
                          self.$t(
                            "city-page.city-weather.global-graph.text.heat.legend3"
                          ) +
                          " : " +
                          Math.round(context.raw);
                      }
                    } else if (self.activeTypeWeatherChart === "prec") {
                      if (
                        context.dataset.label ===
                        "Moyenne des précipitations 2011-2020"
                      ) {
                        resultString =
                          self.$t(
                            "city-page.city-weather.global-graph.text.prec.legend2"
                          ) +
                          " : " +
                          Math.round(context.raw) +
                          "mm";
                      } else if (
                        context.dataset.label ===
                        "Moyenne des précipitations 1981-2010"
                      ) {
                        resultString =
                          self.$t(
                            "city-page.city-weather.global-graph.text.prec.legend1"
                          ) +
                          " : " +
                          Math.round(context.raw) +
                          "mm";
                      } else {
                        resultString =
                          self.$t(
                            "city-page.city-weather.global-graph.text.prec.legend3"
                          ) +
                          " : " +
                          Math.round(context.raw) +
                          "mm";
                      }
                    } else {
                      if (
                        context.dataset.label ===
                        "Nombre de jours de gel 2011-2020"
                      ) {
                        resultString =
                          self.$t(
                            "city-page.city-weather.global-graph.text.frost.legend2"
                          ) +
                          " : " +
                          Math.round(context.raw);
                      } else if (
                        context.dataset.label ===
                        "Nombre de jours de gel 1981-2010"
                      ) {
                        resultString =
                          self.$t(
                            "city-page.city-weather.global-graph.text.frost.legend1"
                          ) +
                          " : " +
                          Math.round(context.raw);
                      } else {
                        resultString =
                          self.$t(
                            "city-page.city-weather.global-graph.text.frost.legend3"
                          ) +
                          " : " +
                          Math.round(context.raw);
                      }
                    }
                  }
                  return resultString;
                },
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              title: {
                display: false,
                text: "Nombre de jours",
              },
              grid: {
                display: true,
                tickLength: 0,
              },
              ticks: {
                padding: 7,
              },
            },
            x: {
              grid: {
                display: false,
              },
            },
          },
        },
      });
    },
    // Toggle legend
    toggleEntry(legend) {
      var element = document.getElementById(legend);
      var scale = legend.slice(-2);

      // Compute value to select good index
      var quotient = 0;
      if (this.activeTypeWeatherChart === "frost") {
        quotient = 0;
      } else if (this.activeTypeWeatherChart === "heat") {
        quotient = 3;
      } else {
        quotient = 6;
      }

      // Update Button style
      if (element.classList.contains("city-weather-legend-btn-inactive")) {
        element.classList.remove("city-weather-legend-btn-inactive");
        // Display or Hide data
        if (scale === "81") {
          this.extremeChart.setDatasetVisibility(quotient + 0, true);
        } else if (scale === "11") {
          this.extremeChart.setDatasetVisibility(quotient + 1, true);
        } else {
          this.extremeChart.setDatasetVisibility(quotient + 2, true);
        }
      } else {
        element.classList.add("city-weather-legend-btn-inactive");
        // Display or Hide data
        if (scale === "81") {
          this.extremeChart.setDatasetVisibility(quotient + 0, false);
        } else if (scale === "11") {
          this.extremeChart.setDatasetVisibility(quotient + 1, false);
        } else {
          this.extremeChart.setDatasetVisibility(quotient + 2, false);
        }
      }

      this.extremeChart.update();
    },
    // Change displayed dataset
    toggleExtremeChart(evt) {
      var element = document.getElementById("city-weather-legend-btn-11");
      element.classList.add("city-weather-legend-btn-inactive");
      if (
        document
          .getElementById("city-weather-legend-btn-81")
          .classList.contains("city-weather-legend-btn-inactive")
      ) {
        document
          .getElementById("city-weather-legend-btn-81")
          .classList.remove("city-weather-legend-btn-inactive");
      }
      if (
        document
          .getElementById("city-weather-legend-btn-21")
          .classList.contains("city-weather-legend-btn-inactive")
      ) {
        document
          .getElementById("city-weather-legend-btn-21")
          .classList.remove("city-weather-legend-btn-inactive");
      }
      //  If the event is fired by heat button
      if (evt.target.id === "city-weather-chart-button-heat") {
        // Set global filter element
        this.activeTypeWeatherChart = "heat";
        this.legendColor = ["#fcc850", "#ea9c4a", "#8b2845"];
        // hide frost datasets
        for (let index = 0; index < 3; index++) {
          this.extremeChart.setDatasetVisibility(index, false);
        }
        for (let index = 6; index < 9; index++) {
          this.extremeChart.setDatasetVisibility(index, false);
        }
        // show heat datasets
        for (let index = 3; index < 6; index++) {
          if (index !== 4) {
            this.extremeChart.setDatasetVisibility(index, true);
          }
        }

        // Replace the filter : display only the legends of heat datasets
        this.extremeChart.options.plugins.legend.labels.filter = function (
          item
        ) {
          return (
            item.datasetIndex !== 0 &&
            item.datasetIndex !== 1 &&
            item.datasetIndex !== 2 &&
            item.datasetIndex !== 6 &&
            item.datasetIndex !== 7 &&
            item.datasetIndex !== 8
          );
        };
      } else if (evt.target.id === "city-weather-chart-button-frost") {
        this.activeTypeWeatherChart = "frost";
        this.legendColor = ["#c4e2f3", "#6392c8", "#113689"];
        // show frost datasets
        for (let index = 0; index < 3; index++) {
          if (index !== 1) {
            this.extremeChart.setDatasetVisibility(index, true);
          }
        }
        // hide heat datasets
        for (let index = 3; index < 9; index++) {
          this.extremeChart.setDatasetVisibility(index, false);
        }

        // Replace the filter : display only the legends of frost datasets
        this.extremeChart.options.plugins.legend.labels.filter = function (
          item
        ) {
          return (
            item.datasetIndex !== 3 &&
            item.datasetIndex !== 4 &&
            item.datasetIndex !== 5 &&
            item.datasetIndex !== 6 &&
            item.datasetIndex !== 7 &&
            item.datasetIndex !== 8
          );
        };
      } else {
        this.activeTypeWeatherChart = "prec";
        this.legendColor = ["#c4e2f3", "#588cc2", "#113689"];
        // show frost datasets
        for (let index = 6; index < 9; index++) {
          if (index !== 7) {
            this.extremeChart.setDatasetVisibility(index, true);
          }
        }
        // hide heat datasets
        for (let index = 0; index < 6; index++) {
          this.extremeChart.setDatasetVisibility(index, false);
        }

        // Replace the filter : display only the legends of frost datasets
        this.extremeChart.options.plugins.legend.labels.filter = function (
          item
        ) {
          return (
            item.datasetIndex !== 3 &&
            item.datasetIndex !== 4 &&
            item.datasetIndex !== 5 &&
            item.datasetIndex !== 0 &&
            item.datasetIndex !== 1 &&
            item.datasetIndex !== 2
          );
        };
      }

      // Update the chart
      this.extremeChart.update();

      // Change the style of the selected button
      let toggleBtns = document.getElementsByClassName(
        "city-weather-chart-button-active"
      );
      for (let btn of toggleBtns) {
        btn.classList.remove("city-weather-chart-button-active");
      }
      evt.target.classList.toggle("city-weather-chart-button-active");
    },
    // Request key numbers
    async loadGraphNumbers() {
      let graphResponse;
      try {
        // Get data from local Nasa Power api for historical weather information for charts
        graphResponse = await axios.get(
          process.env.VUE_APP_NASA_POWER_LOCAL_URL +
            this.city.name +
            "/get_global_graph_numbers"
        );
        graphResponse = graphResponse.data;
        if (!this.city.weather) {
          this.city.weather = {};
        }
        this.city.weather.graphNumbers = graphResponse;
      } catch (error) {
        console.error(error);
      }
    },
    // Request record
    async getRecords() {
      let recordsResponse;
      try {
        // Get data from local Nasa Power api for historical weather information for charts
        recordsResponse = await axios.get(
          process.env.VUE_APP_NASA_POWER_LOCAL_URL +
            this.city.name +
            "/get_records"
        );
        recordsResponse = recordsResponse.data;
        if (!this.city.weather) {
          this.city.weather = {};
        }
        this.city.weather.records = recordsResponse;
        this.graphNumber = true;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.city-weather-legend-btn {
  margin-right: 5%;
  font-size: 0.9vw;
}

.city-weather-legend-btn-inactive {
  opacity: 0.25;
}

.global-graph-desc-number {
  font-weight: inherit;
  font-size: 1vw;
}

.global-graph-desc-unit {
  font-size: 2vw;
  font-weight: bold;
}

.global-graph-desc-text {
  color: #9facbd;
  font-size: 0.8vw;
}

.global-graph-first-div {
  margin: auto;
  margin-bottom: 1.5vw;
}

.global-graph-title {
  text-align: center;
  font-size: 1.2vw;
  font-weight: bold;
}

.global-graph-first-row-first-div {
  margin: auto;
  margin-top: 0;
  min-width: 23vw;
  text-align: center;
}

.third-row-class {
  margin-left: 10%;
  margin-right: 10%;
}

.city-weather-chart {
  background-color: white;
  border-radius: 15px;
  padding: 1% 3% 1% 1%;
}

.global-graph-desc-legends {
  font-size: 0.9vw;
  border-bottom: 1px solid #adb8c6;
  padding-bottom: 3px;
}

@media screen and (min-width: 800px) {
  .city-weather-legend-btn:hover {
    opacity: 0.25;
  }

  .city-weather-legend-btn-inactive:hover {
    opacity: 0.75;
  }
}

@media screen and (max-width: 800px) {
  .global-graph-desc-legends {
    font-size: 18px;
  }

  .global-graph-title {
    font-size: 18px;
  }

  .global-graph-first-div {
    margin: auto;
    margin-bottom: 5%;
  }

  .global-graph-first-row-first-div {
    min-width: 300px;
    margin: inherit;
  }

  .city-weather-chart-button {
    margin: auto;
    font-size: 12px;
  }

  .third-row-class {
    margin-left: 1%;
    margin-right: 0;
  }

  .city-weather-chart {
    min-height: 300px;
    max-height: 300px;
    max-width: 90vw;
    height: 300px;
    width: inherit;
    margin: 0 !important;
  }

  .city-weather-legend-btn {
    font-size: 12px;
  }

  .global-graph-desc-unit {
    font-size: 24px;
  }

  .global-graph-desc-number {
    font-size: 16px;
  }

  .global-graph-desc-text {
    font-size: 12px;
    max-width: 40vw;
  }
}
</style>
