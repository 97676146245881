<template>
  <v-card elevation="15" shaped max-width="50vw">
    <v-container class="pa-10" fill-height>
      <v-row align="center" justify="center">
        <v-col cols="2">
          <v-img
            :src="require('@/assets/images/Icon-world.png')"
            :lazy-src="require('@/assets/images/Icon-world.png')"
            max-height="80px"
            contain
          >
          </v-img>
        </v-col>
        <v-col>
          <span class="city-data-process-right-title">{{
            $t("city-page.kermap-data.title")
          }}</span>
          <p class="city-data-process-right-text">
            {{ $t("city-page.kermap-data.content") }}
            <span style="font-weight: bold; text-decoration: underline">{{
              $t("city-page.kermap-data.about")
            }}</span
            >.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "CityKermapData",
};
</script>

<style scoped>
.city-data-process-right-title {
  color: #0d2766;
}

.city-data-process-right-text {
  color: #143399;
}

.city-data-process-right-anchor {
  color: #203d8d;
}
</style>
