<template>
  <!-- Component which is used in Home and Featured page -->
  <div>
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-card
          class="city-card-div"
          :style="
            'bottom: ' +
            (hover ? '8px' : '0px') +
            ';box-shadow: 0px 0px 30px ' +
            (hover ? '15' : '0') +
            'px #D2D2D2 !important;'
          "
          height="auto"
          :href="cityUrl"
        >
          <v-img
            class="city-card-div-img"
            :src="require('@/assets/images/' + city[0] + '.jpg')"
            :lazy-src="require('@/assets/images/' + city[0] + '.jpg')"
            cover
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate />
              </v-row>
            </template>
          </v-img>
          <div class="city-card-text">
            <div>
              <div class="city-card-name">
                {{ $t("city." + city[0] + ".name") }}
              </div>
              <div class="city-card-country">
                {{ $t("city." + city[0] + ".country") }}
              </div>
            </div>

            <div class="city-card-info-text">
              <div class="city-card-info-text-item">
                <v-icon v-if="!isMobile" color="grey" size="1.5vw">
                  $vuetify.icon.mean_deviation
                </v-icon>
                <v-icon v-if="isMobile" color="grey" size="24px">
                  $vuetify.icon.mean_deviation
                </v-icon>
                <span class="span-value">
                  {{
                    (city[1].weather.meanDeviationYearly.C[
                      city[1].weather.meanDeviationYearly.C.length - 1
                    ] >= 0
                      ? "+"
                      : "") +
                    $n(
                      city[1].weather.meanDeviationYearly.C[
                        city[1].weather.meanDeviationYearly.C.length - 1
                      ].toFixed(2)
                    ) +
                    "°C"
                  }}
                </span>
                <InfoBtn :is-mobile="isMobile" :temperature="true" />
              </div>

              <div class="city-card-info-text-item">
                <v-icon v-if="!isMobile" color="#80ba28" size="1.5vw">
                  $vuetify.icon.klover_score
                </v-icon>
                <v-icon v-if="isMobile" color="#80ba28" size="24px">
                  $vuetify.icon.klover_score
                </v-icon>
                <span class="span-value">
                  {{ $n(Math.round(city[1].PercVeg)) }}
                  <span class="minus-span-value">/100</span>
                </span>

                <InfoBtn :is-mobile="isMobile" :temperature="false" />
              </div>
            </div>
          </div>
        </v-card>
      </template>
    </v-hover>
  </div>
</template>

<script>
import InfoBtn from "@/components/Description/information/InfoBtn";

export default {
  name: "CityCard",
  components: {
    InfoBtn,
  },
  filters: {
    // shorten the temperature number
    getRound: function (value) {
      if (value > 999 && value < 1000000) {
        return (value / 1000).toFixed(1) + "K";
      } else if (value > 1000000) {
        return (value / 1000000).toFixed(1) + "M";
      } else if (value < 900) {
        return value;
      }
    },
    getPercentage: function (value) {
      return value.toLocaleString("en", { style: "percent" });
    },
  },
  props: {
    // the city object displayed on the card
    city: {
      type: Array,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      cityUrl: "",
      temperatureInfos: false,
      greeneryInfos: false,
    };
  },
  async mounted() {
    // make the city page url of the city in property
    if (this.$i18n.locale === "fr") {
      this.cityUrl = "/ville/" + this.city[0];
    } else {
      this.cityUrl = "/" + this.city[0];
    }
  },
};
</script>

<style scoped>
.city-card-div-img {
  height: 10vw;
}

.span-value {
  font-size: 0.95vw;
}

.minus-span-value {
  font-size: 0.7vw;
}

@media screen and (max-width: 800px) {
  .city-card-div {
    width: 80vw;
  }

  .city-card-div-img {
    height: inherit;
  }

  .city-card-name {
    font-size: 20px;
  }

  .city-card-country {
    font-size: 12px;
  }

  .span-value {
    font-size: 14px;
  }

  .minus-span-value {
    font-size: 10px;
  }

  @media screen and (orientation: landscape) {
    .city-card-div {
      width: 50vw;
    }
  }
}
</style>
