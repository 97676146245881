import { render, staticRenderFns } from "./CityText2.vue?vue&type=template&id=d0a75742&scoped=true&"
import script from "./CityText2.vue?vue&type=script&lang=js&"
export * from "./CityText2.vue?vue&type=script&lang=js&"
import style0 from "./CityText2.vue?vue&type=style&index=0&id=d0a75742&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0a75742",
  null
  
)

export default component.exports