<!-- Download popup component -->
<template>
  <v-dialog
    id="popup"
    v-model="display"
    max-width="600px"
    height="900px"
    style="border-radius: 15px"
    scrollable
    persistent
  >
    <v-card min-height="60vh" color="#eff2f7">
      <v-btn x-large icon @click="closePopup">
        <v-icon> mdi-close </v-icon>
      </v-btn>
      <v-card-text>
        <!-- Script handled by SendInBlue -->
        <iframe
          v-if="$i18n.locale === 'fr'"
          width="100%"
          height="100%"
          :src="frLink"
          frameborder="0"
          scrolling="auto"
          class="pt-0 mt-0"
          style="
            display: block;
            margin-left: auto;
            margin-right: auto;
            max-width: 100%;
          "
        ></iframe>
        <iframe
          v-if="$i18n.locale === 'en'"
          width="100%"
          height="100%"
          :src="enLink"
          frameborder="0"
          scrolling="auto"
          class="pt-0 mt-0"
          style="
            display: block;
            margin-left: auto;
            margin-right: auto;
            max-width: 100%;
          "
        ></iframe>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Popup",
  props: {
    display: {
      required: true,
      type: Boolean,
    },
    frLink: {
      required: true,
      type: String,
    },
    enLink: {
      required: true,
      type: String,
    },
  },
  methods: {
    closePopup() {
      this.$emit("closePopup");
    },
  },
};
</script>

<style scoped>
>>> .v-dialog {
  overflow-y: hidden !important;
}

.sib-form {
  padding-top: 0 !important;
}
</style>
