<template>
  <v-container
    fill-height
    fluid
    :class="isMobile ? 'pb-0' : 'pt-0 pb-0'"
    :style="isMobile ? 'padding-top: 8%' : ''"
  >
    <v-row class="city-weather-main-row" align="center">
      <!-- First row, left column -->
      <v-col :class="isMobile ? 'pa-0' : ''" :cols="isMobile ? '12' : 'pt-0'">
        <v-container :class="isMobile ? 'pa-0' : ''">
          <h3 class="city-weather-text-title">
            {{ $t("city-page.city-weather.title") }}
            {{ $t("city." + city.name + ".name") }} ?
          </h3>
          <p class="city-weather-text-content">
            {{ $t("city-page.city-weather.content.part1")
            }}{{ $t("city." + city.name + ".name") }} :
            {{ $t("city-page.city-weather.content.part2") }}
          </p>
          <p class="pt-15" style="color: #aabcc6; font-size: 0.8vw">
            {{ $t("city-page.city-weather.update") }}{{ lastUpdate }}
          </p>
        </v-container>
      </v-col>
      <v-col>
        <!-- Switch content: first row right column -->
        <v-container>
          <label class="switch">
            <p ref="right-value" class="city-weather-switch-left-content-value">
              {{
                city.weather.meanDeviationYearly.C[
                  city.weather.meanDeviationYearly.C.length - 1
                ] >= 0
                  ? "+"
                  : ""
              }}{{
                city.weather.meanDeviationYearly.C[
                  city.weather.meanDeviationYearly.C.length - 1
                ].toFixed(2)
              }}°<v-icon
                v-if="!isMobile"
                size="2vw"
                :color="
                  city.weather.meanDeviationYearly.C[
                    city.weather.meanDeviationYearly.C.length - 1
                  ] >= 0
                    ? '#b92d2e'
                    : '#12368b'
                "
                >mdi-thermometer-low</v-icon
              ><v-icon
                v-if="isMobile"
                size="16px"
                :color="
                  city.weather.meanDeviationYearly.C[
                    city.weather.meanDeviationYearly.C.length - 1
                  ] >= 0
                    ? '#b92d2e'
                    : '#12368b'
                "
                >mdi-thermometer-low</v-icon
              >
            </p>
            <p ref="right-year" class="city-weather-switch-left-content-year">
              {{ currentDate.getFullYear() }}
            </p>
            <span id="slider" class="slider round"></span>
            <p ref="left-value" class="city-weather-switch-right-content-value">
              {{
                city.weather.meanDeviation.ECART[
                  city.weather.meanDeviation.ECART.length - 1
                ] >= 0
                  ? "+"
                  : ""
              }}{{
                city.weather.meanDeviation.ECART[
                  city.weather.meanDeviation.ECART.length - 1
                ]
              }}°<v-icon
                v-if="!isMobile"
                size="2vw"
                :color="
                  city.weather.meanDeviation.ECART[
                    city.weather.meanDeviation.ECART.length - 1
                  ] >= 0
                    ? '#b92d2e'
                    : '#12368b'
                "
                >mdi-thermometer-low</v-icon
              ><v-icon
                v-if="isMobile"
                size="16px"
                :color="
                  city.weather.meanDeviation.ECART[
                    city.weather.meanDeviation.ECART.length - 1
                  ] >= 0
                    ? '#b92d2e'
                    : '#12368b'
                "
                >mdi-thermometer-low</v-icon
              >
            </p>
            <p ref="left-year" class="city-weather-switch-right-content-year">
              {{ $t("month[" + currentDate.getMonth() + "]") }}
              {{ currentDate.getFullYear() }}
            </p>
          </label>
          <v-subheader class="switch-subheader">
            {{ $t("city-page.city-weather.legend") }}
          </v-subheader>
        </v-container>
      </v-col>
    </v-row>
    <!-- Load graphs -->
    <v-row class="city-weather-graph-row" align="center">
      <v-col
        :cols="isMobile ? '12' : ''"
        :class="isMobile ? 'pl-2 pr-2' : 'pt-0 pb-0'"
      >
        <TemperatureMoyenne
          :city="city"
          :is-mobile="isMobile"
        ></TemperatureMoyenne>
      </v-col>
      <v-col
        :cols="isMobile ? '12' : ''"
        :class="isMobile ? 'pa-0' : 'pt-0 pb-0'"
      >
        <EcartTemperature :city="city"></EcartTemperature>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TemperatureMoyenne from "@/components/City/graph/MeanTemperature";
import EcartTemperature from "@/components/City/graph/EcartTemperature";
import axios from "axios";

export default {
  name: "CityWeather2",
  components: {
    EcartTemperature,
    TemperatureMoyenne,
  },
  props: {
    city: {
      required: true,
      type: Object,
    },
    isMobile: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentDate: new Date(),
      lastUpdate: undefined,
    };
  },
  // Get last climate data update date
  async created() {
    var self = this;
    await axios
      .get(process.env.VUE_APP_NASA_POWER_LOCAL_URL + "/get_date_update")
      .then(function (response) {
        self.lastUpdate = response.data;
      });
  },
  // Compute switch background color according to the mean deviation of the city
  mounted() {
    var gradient;
    if (
      this.city.weather.meanDeviationYearly.C[
        this.city.weather.meanDeviationYearly.C.length - 1
      ] >= 0 &&
      this.city.weather.meanDeviation.ECART[
        this.city.weather.meanDeviation.ECART.length - 1
      ] >= 0
    ) {
      if (
        this.city.weather.meanDeviationYearly.C[
          this.city.weather.meanDeviationYearly.C.length - 1
        ] >
        this.city.weather.meanDeviation.ECART[
          this.city.weather.meanDeviation.ECART.length - 1
        ]
      ) {
        gradient =
          "linear-gradient(90deg, rgba(194,20,56,1) 0%, rgba(231,128,17,1) 50%, rgba(255,218,54,1) 100%);";
      } else {
        gradient =
          "linear-gradient(90deg, rgba(255,218,54,1) 0%, rgba(231,128,17,1) 50%, rgba(194,20,56,1) 100%);";
      }
    } else if (
      this.city.weather.meanDeviationYearly.C[
        this.city.weather.meanDeviationYearly.C.length - 1
      ] < 0 &&
      this.city.weather.meanDeviation.ECART[
        this.city.weather.meanDeviation.ECART.length - 1
      ] < 0
    ) {
      if (
        this.city.weather.meanDeviationYearly.C[
          this.city.weather.meanDeviationYearly.C.length - 1
        ] <
        this.city.weather.meanDeviation.ECART[
          this.city.weather.meanDeviation.ECART.length - 1
        ]
      ) {
        gradient =
          "linear-gradient(90deg, rgba(18,54,140,1) 0%, rgba(17,143,231,1) 50%, rgba(97,192,255,1) 100%);";
      } else {
        gradient =
          "linear-gradient(90deg, rgba(97,192,255,1) 0%, rgba(17,143,231,1) 50%, rgba(18,54,140,1) 100%);";
      }
    } else {
      if (
        this.city.weather.meanDeviationYearly.C[
          this.city.weather.meanDeviationYearly.C.length - 1
        ] >
        this.city.weather.meanDeviation.ECART[
          this.city.weather.meanDeviation.ECART.length - 1
        ]
      ) {
        gradient =
          "linear-gradient(90deg, rgba(242, 191, 71, 1) 0%, rgba(163, 192, 172, 1) 50%, rgba(97,192,255,1) 100%);";
      } else {
        gradient =
          "linear-gradient(90deg, rgba(97,192,255,1) 0%, rgba(163, 192, 172, 1) 50%, rgba(242, 191, 71, 1) 100%);";
      }
    }
    document.getElementById("slider").style = "background: " + gradient;
  },
};
</script>

<style scoped>
.city-weather-graph-row {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 60px;
  margin-bottom: 60px;
}

.city-weather-main-row {
  margin-top: 60px;
  margin-left: 10%;
  margin-right: 10%;
}

.city-weather-text-title {
  font-size: 1.4vw;
  margin-bottom: 8%;
  max-width: 30vw;
}

.city-weather-text-content {
  max-width: 27vw;
}

/* The switch - the box around the slider */
.switch {
  margin-left: 0.5vw;
  position: relative;
  display: inline-block;
  width: 27.5vw;
  height: 13vw;
  left: 2.5vw;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12.5vw;
  width: 13vw;
  right: 1%;
  bottom: 2%;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

/* Rounded sliders */
.slider.round {
  border-radius: 125px;
}

.slider.round:before {
  border-radius: 50%;
}

.city-weather-switch-left-content-value {
  color: white;
  position: absolute;
  font-size: 2.5vw;
  z-index: 2;
  top: 35%;
  left: 13%;
}

.city-weather-switch-left-content-year {
  color: white;
  position: absolute;
  z-index: 999;
  top: 60%;
  left: 23%;
  font-weight: bold;
  font-size: 0.8vw;
}

.city-weather-switch-right-content-value {
  color: #12348b;
  position: absolute;
  font-size: 2.5vw;
  z-index: 2;
  top: 35%;
  right: 8%;
}

.city-weather-switch-right-content-year {
  color: #12348b;
  position: absolute;
  z-index: 999;
  top: 60%;
  right: 16%;
  font-weight: bold;
  font-size: 0.8vw;
}

.switch-subheader {
  position: relative;
  left: 4.8vw;
  color: #aabcc6;
  font-size: 0.8vw;
}

@media screen and (max-width: 800px) {
  .city-weather-text-title {
    font-size: 24px;
    max-width: inherit;
  }

  .city-weather-main-row {
    margin: 0;
  }

  .city-weather-text-content {
    font-size: 16px;
    max-width: inherit;
  }

  .switch {
    margin-left: 5px;
    width: 270px;
    height: 130px;
    left: 0px;
    margin-top: 5%;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 125px;
    width: 130px;
    right: 1%;
    bottom: 2%;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .city-weather-switch-left-content-value {
    font-size: 24px;
    z-index: 3;
  }

  .city-weather-switch-left-content-year {
    font-size: 10px;
    z-index: 3;
  }

  .city-weather-switch-right-content-value {
    font-size: 24px;
    z-index: 3;
  }

  .city-weather-switch-right-content-year {
    font-size: 10px;
    z-index: 3;
  }

  .switch-subheader {
    font-size: 10px;
    left: inherit;
  }

  .city-weather-graph-row {
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 0;
    margin-right: 0;
  }

  @media screen and (orientation: landscape) {
    .switch {
      width: 60vw;
      height: 55vh;
      left: 13%;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 53vh;
      width: 30vw;
      right: 1%;
      bottom: 2%;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .switch-subheader {
      font-size: 12px;
      left: 13%;
    }

    .city-weather-switch-left-content-value {
      font-size: 32px;
      top: 33%;
    }

    .city-weather-switch-left-content-year {
      font-size: 14px;
    }

    .city-weather-switch-right-content-value {
      font-size: 32px;
      top: 33%;
    }

    .city-weather-switch-right-content-year {
      font-size: 14px;
      right: 13%;
    }
  }
}
</style>
